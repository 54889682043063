import { useMutation } from '@tanstack/react-query';
import api from '../../api';
import { saveAuthData } from './storage';

interface SignupData {
  first_name: string;
  email: string;
  password: string;
  confirm_password: string;
  terms_accepted: boolean;
}

interface SignupResponse {
  message: string;
  access: string;
  refresh: string;
  first_name: string;
  email: string;
  game_count: number;
}

const signupUser = async (data: SignupData): Promise<SignupResponse> => {
  const response = await api.post('/accounts/signup/', data);
  return response.data;
};

export const useSignupUser = () => {
  return useMutation(signupUser, {
    onSuccess: (data, variables, context) => {
      // Save auth data so the frontend sees the user as logged in
      saveAuthData({
        access: data.access,
        refresh: data.refresh,
        first_name: data.first_name || '',
        email: variables.email,
      });

      // Save game_count (or any additional user data)
      if (data.game_count !== undefined) {
        localStorage.setItem('userGames', data.game_count.toString());
      }

      // Dispatch a storage event so other components or windows can pick up the change
      window.dispatchEvent(new Event('storage'));
    },
  });
};
