import React from 'react';
import QuestionCard from './QuestionCard';
import TeamCorner from '../Game/TeamCorner';
import { Team, TeamAbilities } from '../../pages/Game';

interface DesktopLayoutProps {
  questionPoints: number;
  questionTime: string;
  questionText: string;
  // Pass separate arrays for each media position
  mediaBefore: string[] | null;
  mediaWith: string[] | null;
  mediaAfter: string[] | null;
  mediaType?: 'video' | 'image';
  onClose: () => void;
  onShowAnswer: () => void;
  teams: Team[];
  currentTurn: number;
  onScoreChange: (teamId: number, delta: number) => void;
  onAbilityClick: (
    teamId: number,
    ability: 'callFriend' | 'twoAnswers' | 'replaceQuestion'
  ) => void;
  teamAbilities: Record<number, TeamAbilities>;
  // New prop to determine if replacement ability should show on picker
  canReplaceQuestion: (team: Team) => boolean;
}

const DesktopLayout: React.FC<DesktopLayoutProps> = ({
  questionPoints,
  questionTime,
  questionText,
  mediaBefore,
  mediaWith,
  mediaAfter,
  mediaType,
  onClose,
  onShowAnswer,
  teams,
  currentTurn,
  onScoreChange,
  onAbilityClick,
  teamAbilities,
  canReplaceQuestion,
}) => {
  const half = Math.ceil(teams.length / 2);
  const leftTeams = teams.slice(0, half);
  const rightTeams = teams.slice(half);

  return (
    <div className="hidden md:flex max-w-7xl mx-auto px-4 items-start justify-between gap-16 flex-nowrap">
      {/* Left Teams */}
      <div className="flex flex-col items-center gap-4">
        {leftTeams.map((team, idx) => (
          <div key={team.id} className="w-40">
            <TeamCorner
              team={team}
              index={idx}
              isCurrent={idx === currentTurn}
              onScoreChange={onScoreChange}
              teamAbilities={teamAbilities}
              onAbilityClick={onAbilityClick}
              canReplaceQuestion={canReplaceQuestion(team)}
              universalAbilitiesEnabled={true}
            />
          </div>
        ))}
      </div>

      {/* Center Question */}
      <div className="flex-1 min-w-[350px] flex justify-center">
        <QuestionCard
          questionPoints={questionPoints}
          formattedTime={questionTime}
          questionText={questionText}
          media={mediaBefore?.[0] || mediaWith?.[0] || mediaAfter?.[0] || null}
          mediaType={mediaType}
          onClose={onClose}
          onShowAnswer={onShowAnswer}
        />
      </div>

      {/* Right Teams */}
      <div className="flex flex-col items-center gap-4 ml-10">
        {rightTeams.map((team, idx) => {
          const realIndex = idx + half;
          return (
            <div key={team.id} className="w-40">
              <TeamCorner
                team={team}
                index={realIndex}
                isCurrent={realIndex === currentTurn}
                onScoreChange={onScoreChange}
                teamAbilities={teamAbilities}
                onAbilityClick={onAbilityClick}
                canReplaceQuestion={canReplaceQuestion(team)}
                universalAbilitiesEnabled={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DesktopLayout;
