// src/services/mutations/auth/useResetPassword.ts

import { useMutation } from '@tanstack/react-query';
import api from '../../api'; // Adjust import if your API instance is located elsewhere

interface ResetPasswordData {
  token: string;
  password: string;
  confirm_password: string;
}

const resetPasswordUser = async (data: ResetPasswordData): Promise<any> => {
  // POST /accounts/reset-password/ with { token, password, confirm_password }
  const response = await api.post('/accounts/reset-password/', data);
  return response.data;
};

export const useResetPassword = () => {
  return useMutation(resetPasswordUser);
};
