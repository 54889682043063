import React, { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getAuthData, saveAuthData } from '../services/mutations/auth/storage';
import { usePurchasePackageMutation } from '../services/mutations/payments/purchasePackage';

const Success: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutate: purchasePackage, data, isLoading, error } = usePurchasePackageMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stripeSessionId = queryParams.get('session_id');
    const packageIdString = queryParams.get('package_id');
    if (stripeSessionId && packageIdString) {
      const package_id = parseInt(packageIdString, 10);
      purchasePackage(
        { package_id, stripe_session_id: stripeSessionId },
        {
          onSuccess: (data) => {
            if (data?.game_count !== undefined) {
              // Update localStorage with the new game count.
              const authData = getAuthData();
              saveAuthData({
                access: authData.access || '',
                refresh: authData.refresh || '',
                first_name: authData.first_name || '',
                email: authData.email || '',
                userGames: data.game_count.toString(),
              });
              // Dispatch custom event to update Navbar immediately.
              window.dispatchEvent(new CustomEvent('gameCountUpdated', { detail: data.game_count }));
            }
          },
        }
      );
    }
  }, [location.search, purchasePackage]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="text-center">
          <p className="text-xl text-yellow-800">Processing your purchase...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="max-w-md mx-auto bg-white rounded-2xl p-8 shadow-lg border border-yellow-300 text-center">
          <h1 className="text-3xl font-bold text-red-600 mb-4">Error</h1>
          <p className="text-lg text-gray-700">There was an error processing your purchase. Please try again.</p>
          <Link 
            to="/" 
            className="mt-6 inline-block bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-xl"
          >
            Return Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="max-w-md mx-auto bg-white rounded-2xl p-8 shadow-lg border border-yellow-300 text-center">
        <h1 className="text-4xl font-bold text-yellow-800 mb-4">
          Thank you for your purchase!
        </h1>
        {data && data.success ? (
          <p className="text-lg text-gray-700 mb-6">{data.detail}</p>
        ) : (
          <p className="text-lg text-gray-700 mb-6">Your package has been successfully activated.</p>
        )}
        <Link 
          to="/" 
          className="inline-block bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded-xl"
        >
          Return to Home
        </Link>
      </div>
    </div>
  );
};

export default Success;
