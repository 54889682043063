import React from 'react';
import QuestionCard from './QuestionCard';
import TeamCorner from '../Game/TeamCorner';
import { Team, TeamAbilities } from '../../pages/Game';

interface MobileLayoutProps {
  questionPoints: number;
  questionTime: string;
  questionText: string;
  mediaBefore: string[] | null;
  mediaWith: string[] | null;
  mediaAfter: string[] | null;
  mediaType?: 'video' | 'image';
  onClose: () => void;
  onShowAnswer: () => void;
  teams: Team[];
  currentTurn: number;
  onScoreChange: (teamId: number, delta: number) => void;
  onAbilityClick: (
    teamId: number,
    ability: 'callFriend' | 'twoAnswers' | 'replaceQuestion'
  ) => void;
  teamAbilities: Record<number, TeamAbilities>;
  canReplaceQuestion: (team: Team) => boolean;
}

const MobileLayout: React.FC<MobileLayoutProps> = ({
  questionPoints,
  questionTime,
  questionText,
  mediaBefore,
  mediaWith,
  mediaAfter,
  mediaType,
  onClose,
  onShowAnswer,
  teams,
  currentTurn,
  onScoreChange,
  onAbilityClick,
  teamAbilities,
  canReplaceQuestion,
}) => {
  if (!teams || teams.length === 0) {
    return null;
  }

  return (
    <div className="md:hidden w-full max-w-2xl mx-auto px-4 flex flex-col gap-4">
      <QuestionCard
        questionPoints={questionPoints}
        formattedTime={questionTime}
        questionText={questionText}
        media ={mediaBefore?.[0] || mediaWith?.[0] || mediaAfter?.[0] || null}
        mediaType={mediaType}
        onClose={onClose}
        onShowAnswer={onShowAnswer}
      />

      <div className="flex flex-col items-center gap-4">
        {teams.map((team, idx) => (
          <div key={team.id} className="w-full">
            <TeamCorner
              team={team}
              index={idx}
              isCurrent={idx === currentTurn}
              onScoreChange={onScoreChange}
              teamAbilities={teamAbilities}
              onAbilityClick={onAbilityClick}
              canReplaceQuestion={canReplaceQuestion(team)}
              universalAbilitiesEnabled={true}
              expanded={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileLayout;
