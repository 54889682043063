import { useMutation } from '@tanstack/react-query';
import api from '../../api';

export interface ContactData {
  name: string;
  email: string;
  message: string;
}

const sendContactEmail = async (data: ContactData): Promise<any> => {
  const response = await api.post('/accounts/contact/', data);
  return response.data;
};

export const useContactEmail = () => {
  return useMutation(sendContactEmail);
};
