// src/components/QuestionScreen.tsx
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Question, Team, TeamAbilities, GameData } from './Game';
import { useUpdateGameProgressMutation } from '../services/mutations/game/updateGameProgress';
import useUpdateTeamAbilitiesMutation from '../services/mutations/game/useUpdateTeamAbilities';
import MobileLayout from '../components/QuestionScreen/MobileLayout';
import DesktopLayout from '../components/QuestionScreen/DesktopLayout';
import {
  PhoneCallConfirm,
  PhoneCallOverlay,
  ReplaceQuestionConfirm
} from '../components/QuestionScreen/QuestionOverlays';
import logo from '../assets/logo/logo.png';

export interface ExtendedQuestion extends Question {}

type Phase = 'picker' | 'opponents' | 'finished';

interface QuestionScreenProps {
  question: ExtendedQuestion;
  onOptionClick: (selectedOption: string, optionIndex: number) => void;
  onNoOption: () => void;
  onClose: () => void;
  localGameData: GameData;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<ExtendedQuestion | null>>;
  teamAbilities: Record<number, TeamAbilities>;
  setTeamAbilities: React.Dispatch<React.SetStateAction<Record<number, TeamAbilities>>>;
  currentTeam: Team;
  onScoreChange: (teamId: number, delta: number) => void;
  mediaBefore: string[] | null;
  mediaWith: string[] | null;
}

const QuestionScreen: React.FC<QuestionScreenProps> = ({
  question,
  onOptionClick,
  onNoOption,
  onClose,
  localGameData,
  setCurrentQuestion,
  teamAbilities,
  setTeamAbilities,
  currentTeam,
  onScoreChange,
  mediaBefore,
  mediaWith,
}) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [phase, setPhase] = useState<Phase>('picker');
  const [answeringIndex, setAnsweringIndex] = useState(0);
  const [displayedTeam, setDisplayedTeam] = useState<Team | null>(null);
  const [showPhoneCallConfirm, setShowPhoneCallConfirm] = useState(false);
  const [phoneCallActive, setPhoneCallActive] = useState(false);
  const [phoneCallTimer, setPhoneCallTimer] = useState(40);
  const [selectedTeamForPhoneCall, setSelectedTeamForPhoneCall] = useState<Team | null>(null);
  const [showReplaceQuestionConfirm, setShowReplaceQuestionConfirm] = useState(false);
  const hasBeforeMedia = question.media_items?.some(mi => mi.position === 'before') || false;
  const [showQuestion, setShowQuestion] = useState(!hasBeforeMedia);

  const updateProgress = useUpdateGameProgressMutation();
  const updateTeamAbility = useUpdateTeamAbilitiesMutation();

  const teams = localGameData.teams;
  const pickerTeam = teams[localGameData.current_turn] || teams[0];
  const opponentTeams = teams.filter(t => t.id !== pickerTeam.id);

  useEffect(() => {
    console.log("Question prop:", question);
    console.log("Category id:", question.category);
  }, [question]);

  const canReplaceQuestionForTeam = (team: Team): boolean => {
    return team.id === pickerTeam.id && phase === 'picker' && timer < 60;
  };

  const activeTeamIndex =
    phase === 'picker'
      ? localGameData.current_turn
      : displayedTeam
      ? teams.findIndex(t => t.id === displayedTeam.id)
      : -1;

  useEffect(() => {
    setDisplayedTeam(pickerTeam);
    setPhase('picker');
    setTimer(0);
  }, [pickerTeam]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;
    if (showQuestion) {
      timerInterval = setInterval(() => setTimer(prev => prev + 1), 1000);
    }
    return () => clearInterval(timerInterval);
  }, [showQuestion]);

  useEffect(() => {
    if (!showQuestion) return;
    if (phase === 'picker') {
      if (timer >= 60) {
        if (opponentTeams.length > 0) {
          setPhase('opponents');
          setAnsweringIndex(0);
          setTimer(0);
          setDisplayedTeam(opponentTeams[0]);
        } else {
          setPhase('finished');
          setDisplayedTeam(null);
        }
      }
    } else if (phase === 'opponents') {
      const allowedTime = teams.length === 3 ? 15 : 30;
      if (timer >= allowedTime) {
        if (answeringIndex < opponentTeams.length - 1) {
          const nextIndex = answeringIndex + 1;
          setAnsweringIndex(nextIndex);
          setTimer(0);
          setDisplayedTeam(opponentTeams[nextIndex]);
        } else {
          setPhase('finished');
          setDisplayedTeam(null);
        }
      }
    }
  }, [timer, phase, answeringIndex, opponentTeams, teams.length, localGameData, showQuestion]);

  useEffect(() => {
    let intv: NodeJS.Timeout;
    if (phoneCallActive) {
      setPhoneCallTimer(40);
      intv = setInterval(() => {
        setPhoneCallTimer(p => (p > 0 ? p - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(intv);
  }, [phoneCallActive]);

  useEffect(() => {
    if (phoneCallTimer === 0 && phoneCallActive) {
      setPhoneCallActive(false);
      toast('انتهت مدة الاتصال');
    }
  }, [phoneCallTimer, phoneCallActive]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const isPicker = (teamId: number) => teamId === pickerTeam.id;

  const handleAbilityClick = (teamId: number, ability: 'callFriend' | 'twoAnswers' | 'replaceQuestion') => {
    if (ability === 'replaceQuestion') {
      if (teamId !== pickerTeam.id) {
        toast.error('ليس دورك لتفعيل هذه القدرة');
        return;
      }
      setShowReplaceQuestionConfirm(true);
    } else if (ability === 'callFriend') {
      const teamClicked = teams.find(t => t.id === teamId) || null;
      setSelectedTeamForPhoneCall(teamClicked);
      setShowPhoneCallConfirm(true);
    } else if (ability === 'twoAnswers') {
      updateTeamAbility.mutate(
        {
          gameId: localGameData.id,
          teamId: teamId,
          ability_type: 'two_answers'
        },
        {
          onSuccess: () => {
            setTeamAbilities(prev => ({
              ...prev,
              [teamId]: { ...prev[teamId], twoAnswers: true }
            }));
            toast.success('تم تفعيل قدرة الإجابتين');
          },
          onError: (error: any) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.detail &&
              error.response.data.detail.includes('already been used')
            ) {
              setTeamAbilities(prev => ({
                ...prev,
                [teamId]: { ...prev[teamId], twoAnswers: true }
              }));
              toast.info('قدرة الإجابتين مستخدمة بالفعل');
            } else {
              toast.error('فشل في تفعيل قدرة الإجابتين');
            }
          }
        }
      );
    }
  };

  const confirmPhoneCall = () => {
    setShowPhoneCallConfirm(false);
    setPhoneCallActive(true);
    if (!selectedTeamForPhoneCall) return;
    updateTeamAbility.mutate(
      {
        gameId: localGameData.id,
        teamId: selectedTeamForPhoneCall.id,
        ability_type: 'call_friend'
      },
      {
        onSuccess: () => {
          setTeamAbilities(prev => ({
            ...prev,
            [selectedTeamForPhoneCall.id]: { ...prev[selectedTeamForPhoneCall.id], callFriend: true }
          }));
          toast.success(`تم تفعيل قدرة الاتصال لفريق ${selectedTeamForPhoneCall.name}`);
        },
        onError: () => {
          toast.error('فشل في تفعيل قدرة الاتصال');
        }
      }
    );
  };

  const confirmReplaceQuestion = () => {
    updateTeamAbility.mutate(
      {
        gameId: localGameData.id,
        teamId: pickerTeam.id,
        ability_type: 'replace_question',
        categoryId: question.category,
        questionId: question.id,
        difficulty: question.difficulty
      },
      {
        onSuccess: (data) => {
          setCurrentQuestion(data.new_question);
          toast.success('تم استبدال السؤال بنجاح');
        },
        onError: () => {
          toast.error('فشل في استبدال السؤال');
        }
      }
    );
    setShowReplaceQuestionConfirm(false);
  };
  

  const endPhoneCall = () => {
    setPhoneCallActive(false);
    toast('انتهت المكالمة مبكراً');
  };

  const handleScoreChange = (teamId: number, delta: number) => {
    onScoreChange(teamId, delta);
  };

  const computedPoints =
    question.difficulty === 'easy'
      ? 300
      : question.difficulty === 'medium'
      ? 500
      : 700;

  const overlayMedia = mediaBefore && mediaBefore.length > 0 ? mediaBefore[0] : null;

  // Render the "before" media inside a contained card using QuestionCard style.
  const renderBeforeMediaCard = () => {
    return (
      <div className="max-w-md mx-auto">
        <img
          src={overlayMedia!}
          alt="Media Before Question"
          className="w-full h-full object-contain rounded-lg shadow-md"
        />
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-yellow-50 p-4 pt-20 flex flex-col gap-6 relative">
      <div className="fixed top-4 right-4">
        <img src={logo} alt="Logo" className="w-26 h-16" />
      </div>
      <div className="max-w-6xl mx-auto px-4 text-center mb-4">
        {phase === 'finished' ? (
          <p className="text-xl font-bold text-yellow-800 animate-pulse">انتهى الوقت</p>
        ) : (
          <p className="text-xl font-bold text-yellow-800 animate-pulse">
            {phase === 'picker'
              ? `دوركم يا ${pickerTeam.name}`
              : `فرصة ${displayedTeam?.name || ''}`}
          </p>
        )}
      </div>
      {!showQuestion && hasBeforeMedia && overlayMedia ? (
        <>
          {renderBeforeMediaCard()}
          <button
            onClick={() => {
              setShowQuestion(true);
              setTimer(0);
            }}
            className="mt-4 px-6 py-2 bg-blue-500 text-white rounded"
          >
            عرض السؤال
          </button>
        </>
      ) : (
        <>
          <div className="md:hidden max-w-6xl mx-auto px-4">
            <MobileLayout
              questionPoints={computedPoints}
              questionTime={formatTime(timer)}
              questionText={question.question_text}
              mediaBefore={mediaBefore}
              mediaWith={mediaWith}
              mediaAfter={[]}
              mediaType={question.media_items?.[0]?.media_type || 'image'}
              onClose={onClose}
              onShowAnswer={() => onNoOption()}
              teams={teams}
              currentTurn={activeTeamIndex}
              onScoreChange={handleScoreChange}
              onAbilityClick={handleAbilityClick}
              teamAbilities={teamAbilities}
              canReplaceQuestion={() => canReplaceQuestionForTeam(pickerTeam)}
            />
          </div>
          <div className="hidden md:flex max-w-6xl mx-auto px-4 items-start justify-between gap-8">
            <DesktopLayout
              questionPoints={computedPoints}
              questionTime={formatTime(timer)}
              questionText={question.question_text}
              mediaBefore={mediaBefore}
              mediaWith={mediaWith}
              mediaAfter={[]}
              mediaType={question.media_items?.[0]?.media_type || 'image'}
              onClose={onClose}
              onShowAnswer={() => onNoOption()}
              teams={teams}
              currentTurn={activeTeamIndex}
              onScoreChange={handleScoreChange}
              onAbilityClick={handleAbilityClick}
              teamAbilities={teamAbilities}
              canReplaceQuestion={() => canReplaceQuestionForTeam(pickerTeam)}
            />
          </div>
        </>
      )}
      {phoneCallActive && (
        <PhoneCallOverlay timer={phoneCallTimer} onEndCall={endPhoneCall} />
      )}
      {showPhoneCallConfirm && (
        <PhoneCallConfirm
          onConfirm={confirmPhoneCall}
          onCancel={() => setShowPhoneCallConfirm(false)}
          displayedTeamName={selectedTeamForPhoneCall?.name || ''}
        />
      )}
      {showReplaceQuestionConfirm && (
        <ReplaceQuestionConfirm
          onConfirm={confirmReplaceQuestion}
          onCancel={() => setShowReplaceQuestionConfirm(false)}
          pickerTeamName={pickerTeam.name}
        />
      )}
    </div>
  );
};

export default QuestionScreen;
