import api from '../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface UpdateTeamAbilityPayload {
  gameId: number;
  teamId: number;
  ability_type: 'call_friend' | 'two_answers' | 'replace_question';
  // Only required if ability_type === 'replace_question'
  categoryId?: number;
  questionId?: number;
  difficulty?: 'easy' | 'medium' | 'hard';
}

export interface UpdateTeamAbilityResponse {
  detail?: string;
  // For replace_question, the response may include the new question data and updated progress data
  new_question?: any;
  progress_data?: any;
}

const updateTeamAbility = async (
  payload: UpdateTeamAbilityPayload
): Promise<UpdateTeamAbilityResponse> => {
  // Build the payload for the API request.
  const requestData: any = {
    ability_type: payload.ability_type,
  };

  // If replacing a question, include additional parameters.
  if (payload.ability_type === 'replace_question') {
    requestData.category_id = payload.categoryId;
    requestData.question_id = payload.questionId;
    requestData.difficulty = payload.difficulty;
  }

  const response = await api.post(
    `/game/games/${payload.gameId}/teams/${payload.teamId}/use-ability/`,
    requestData
  );
  return response.data;
};

const useUpdateTeamAbilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateTeamAbility, {
    onSuccess: () => {
      queryClient.invalidateQueries(['resume-game']);
    },
    onError: (error) => {
      console.error("Error in updateTeamAbility:", error);
    }
  });
};

export default useUpdateTeamAbilityMutation;
