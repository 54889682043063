import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { usePackages, Package } from '../services/queries/getPackages';

const BuyGames: React.FC = () => {
  const navigate = useNavigate();
  const { data: packages, isLoading, error } = usePackages();

  const handlePurchase = (pack: Package) => {
    // Redirect to your Stripe checkout session with the package ID
    navigate(`/stripe-checkout?package=${pack.id}`);
  };

  if (isLoading) return <p>Loading packages...</p>;
  if (error) return <p>Error loading packages: {error.message}</p>;

  return (
    <div className="min-h-screen bg-white py-10 px-6">
      {/* SEO Helmet */}
      <Helmet>
        <title>شراء ألعاب | حزم مميزة لتجربة لا تُنسى</title>
        <meta
          name="description"
          content="صفحة شراء ألعاب. اختر من بين الحزم المميزة واستمتع بتجربة لعب لا تُنسى. احصل على مزايا وإضافات حصرية."
        />
        <meta
          name="keywords"
          content="شراء ألعاب, حزم ألعاب, باقات, اشتراك, ترفيه, تحدي, أسئلة, تجربة لعب"
        />
        <meta property="og:title" content="شراء ألعاب | حزم مميزة لتجربة لا تُنسى" />
        <meta
          property="og:description"
          content="اختر الحزمة المناسبة لك من Starter, Pro, وUltimate وتمتع بتجربة لعب مميزة."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/assets/logo/logo.png" />
      </Helmet>

      <div className="max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold text-yellow-600 text-center mb-8">شراء ألعاب</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {packages?.map((pack) => (
            <div
              key={pack.id}
              className="border border-yellow-300 rounded-xl p-6 bg-white shadow-sm hover:shadow-md transition-shadow"
            >
              <h2 className="text-2xl font-semibold text-yellow-700 mb-2">{pack.name}</h2>
              <p className="text-gray-800 mb-4">{pack.description}</p>
              <p className="text-xl font-bold text-yellow-700 mb-6">ريال {pack.price}</p>
              <button
                onClick={() => handlePurchase(pack)}
                className="w-full bg-gradient-to-r from-yellow-500 to-yellow-600 text-white font-semibold py-2 rounded-md shadow hover:opacity-90 transition-all"
              >
                شراء الآن
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuyGames;
