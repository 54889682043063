import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { useContactEmail, ContactData } from '../services/mutations/contact/useContactEmail';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const { mutate: sendContactEmail, isLoading } = useContactEmail();
  const navigate = useNavigate();

  // Basic email regex for validation.
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim() || !email.trim() || !message.trim()) {
      toast.error('يرجى ملء جميع الحقول.');
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error('يرجى إدخال بريد إلكتروني صالح.');
      return;
    }

    const data: ContactData = { name, email, message };
    sendContactEmail(data, {
      onSuccess: () => {
        toast.success('تم إرسال رسالتك بنجاح.');
        // Clear fields after success
        setName('');
        setEmail('');
        setMessage('');
      },
      onError: (err: any) => {
        console.error('Contact email error:', err);
        toast.error('حدث خطأ أثناء إرسال رسالتك. الرجاء المحاولة مرة أخرى.');
      },
    });
  };

  return (
    <>
      <Helmet>
        <html lang="ar" dir="rtl" />
        <title>Jawb - تواصل معنا | لعبة الأسئلة والأجوبة التفاعلية</title>
        <meta
          name="description"
          content="تواصل معنا في Jawb، لعبة الأسئلة والأجوبة التفاعلية. نحن نهتم بك ورسالتك مهمة لنا، فلا تتردد في التواصل لطرح استفساراتك أو اقتراحاتك."
        />
        <meta name="keywords" content="Jawb, تواصل معنا, لعبة, أسئلة, أجوبة, Q&A, دعم, اتصل بنا" />
        <link rel="canonical" href="https://www.jawb.com/contact" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "Jawb - تواصل معنا",
              "description": "تواصل معنا لطرح استفساراتك أو اقتراحاتك حول Jawb، لعبة الأسئلة والأجوبة التفاعلية."
            }
          `}
        </script>
      </Helmet>
      <main className="min-h-[calc(100vh-80px)] bg-white py-12">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 text-yellow-600">تواصل معنا</h1>
            <p className="text-lg text-gray-800">
              نحن نهتم بك، ورسالتك تعني لنا الكثير. تواصل معنا في أي وقت، وسنكون هنا للاستماع إليك ومساعدتك بكل حب.
            </p>
            <p className="text-sm text-gray-600 mt-2">
              يرجى قراءة{' '}
              <Link to="/terms-and-conditions" className="text-yellow-600 hover:underline">
                الشروط والأحكام
              </Link>{' '}
              و{' '}
              <Link to="/terms-and-conditions" className="text-yellow-600 hover:underline">
                سياسة الخصوصية
              </Link>{' '}
              قبل إرسال الرسالة.
            </p>
          </div>
          <div className="max-w-2xl mx-auto bg-white rounded-2xl p-8 shadow-lg border border-yellow-300">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-800 mb-2">الاسم</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="أدخل اسمك"
                  className="w-full bg-white border border-gray-300 rounded-xl p-3 text-gray-900 
                             focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-800 mb-2">البريد الإلكتروني</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="أدخل بريدك الإلكتروني"
                  className="w-full bg-white border border-gray-300 rounded-xl p-3 text-gray-900 
                             focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-800 mb-2">الرسالة</label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="اكتب رسالتك هنا..."
                  className="w-full bg-white border border-gray-300 rounded-xl p-3 text-gray-900 h-32 
                             focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-gradient-to-r from-yellow-500 to-yellow-600 text-white py-3 rounded-xl 
                           font-bold hover:opacity-90 transition-opacity"
              >
                {isLoading ? 'جاري الإرسال...' : 'إرسال'}
              </button>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Contact;
