import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGameLogic } from '../components/Game/useGameLogic';
import Header from '../components/Game/Header';
import CategoriesBoard from '../components/Game/CategoriesBoard';
import QuestionScreen from './QuestionScreen';
import RulesModal from '../components/Game/RulesModal';
import LeaveGameModal from '../components/Game/LeaveGameModal';
import AwardScreen from './AwardScreen';

export interface Team {
  id: number;
  name: string;
  score: number;
  call_friend: boolean;
  two_answers: boolean;
  replace_question: boolean;
}

export interface Category {
  id: number;
  name: string;
  description: string;
  image: string | null;
  icon?: string;
}

export interface QuestionMediaItem {
  id: number;
  media: string;
  media_type: 'image' | 'video';
  position: 'before' | 'with' | 'after';
}

export interface Question {
  id: number;
  category: number;
  question_text: string;
  answer: string;
  difficulty: 'easy' | 'medium' | 'hard';
  media_items: QuestionMediaItem[];
}

export interface GameData {
  id: number;
  name: string;
  teams: Team[];
  categories: Category[];
  status: string;
  progress_data: Record<string, {
    easy: { selected: number[]; answered: number[] };
    medium: { selected: number[]; answered: number[] };
    hard: { selected: number[]; answered: number[] };
  }>;
  current_turn: number;
  questions: Question[]; // Ensure questions field is present
  created_at: string;
  updated_at: string;
}

export interface TeamAbilities {
  callFriend: boolean;
  twoAnswers: boolean;
  replaceQuestion: boolean;
}

const Game: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const passedGameData = location.state?.gameData || null;
  const {
    isLoading,
    isError,
    localGameData,
    setLocalGameData,
    currentTeam,
    currentScreen,
    setCurrentScreen,
    isHeaderCollapsed,
    setIsHeaderCollapsed,
    showRulesModal,
    setShowRulesModal,
    showLeaveModal,
    setShowLeaveModal,
    currentQuestion,
    questionModalVisible,
    setQuestionModalVisible,
    closeQuestionModal,
    handleQuestionCircleClick,
    handleOptionClick,
    handleNoOptionAnswer,
    answerChosen,
    handleAward,
    teamAbilities,
    setTeamAbilities,
    handleScoreChange,
    isQuestionAnswered,
    setCurrentQuestion, // Make sure to pass this down
    gameFinished,
    winningTeams,
  } = useGameLogic(passedGameData);

  React.useEffect(() => {
    if (passedGameData) {
      setLocalGameData(passedGameData);
    }
  }, [passedGameData, setLocalGameData]);

  if (isLoading || !localGameData) {
    return (
      <div className="h-screen flex items-center justify-center text-white text-xl">
        Loading game data...
      </div>
    );
  }
  if (isError) {
    return (
      <div className="h-screen flex items-center justify-center text-white text-xl">
        Error loading game data. Please try again.
      </div>
    );
  }

  const mediaBeforeArray = currentQuestion && currentQuestion.media_items
    ? currentQuestion.media_items.filter(m => m.position === 'before').map(m => m.media)
    : [];
  const mediaWithArray = currentQuestion && currentQuestion.media_items
    ? currentQuestion.media_items.filter(m => m.position === 'with').map(m => m.media)
    : [];
  const mediaAfterArray = currentQuestion && currentQuestion.media_items
    ? currentQuestion.media_items.filter(m => m.position === 'after').map(m => m.media)
    : [];

  return (
    <div className="flex flex-col h-screen w-screen overflow-y-auto bg-white text-gray-900 relative">
      {currentScreen === 'game' && (
        <Header
          gameName={localGameData.name}
          onShowRules={() => setShowRulesModal(true)}
          isCollapsed={isHeaderCollapsed}
          onToggleCollapse={() => setIsHeaderCollapsed(prev => !prev)}
          onBack={() => setShowLeaveModal(true)}
        />
      )}
      <div className="flex-grow overflow-y-auto">
        {currentScreen === 'game' && (
          <CategoriesBoard
            categories={localGameData.categories}
            progressData={localGameData.progress_data}
            onQuestionClick={handleQuestionCircleClick}
            isQuestionAnswered={isQuestionAnswered}
            teams={localGameData.teams}
            currentTeam={currentTeam}
            onScoreChange={handleScoreChange}
            teamAbilities={teamAbilities}
          />
        )}
      </div>
      {currentScreen === 'question' && currentQuestion && questionModalVisible && (
        <QuestionScreen
          question={currentQuestion}
          onOptionClick={handleOptionClick}
          onNoOption={handleNoOptionAnswer}
          setCurrentQuestion={setCurrentQuestion}
          onClose={() => {
            setCurrentScreen('game');
            closeQuestionModal();
          }}
          localGameData={localGameData}
          teamAbilities={teamAbilities}
          setTeamAbilities={setTeamAbilities}
          currentTeam={currentTeam}
          onScoreChange={handleScoreChange}
          mediaBefore={mediaBeforeArray}
          mediaWith={mediaWithArray}
        />
      )}
      {currentScreen === 'award-screen' && currentQuestion && (
        <AwardScreen
          question={currentQuestion}
          teams={localGameData.teams}
          onAward={handleAward}
          onBack={() => {
            setQuestionModalVisible(true);
            setCurrentScreen('question');
          }}
          answerChosen={answerChosen}
          mediaAfter={mediaAfterArray}
        />
      )}
      {showRulesModal && <RulesModal onClose={() => setShowRulesModal(false)} />}
      {showLeaveModal && (
        <LeaveGameModal
          onConfirm={() => {
            setShowLeaveModal(false);
            navigate('/');
          }}
          onCancel={() => setShowLeaveModal(false)}
        />
      )}

      {/* Game Finished Modal */}
      {gameFinished && (
  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-8 rounded-xl text-center max-w-md mx-auto shadow-2xl border border-yellow-300">
      <h2 className="text-3xl font-bold mb-4 text-yellow-600">انتهت اللعبة!</h2>
      {winningTeams.length === 1 ? (
        <p className="text-2xl font-bold text-yellow-500">
          الفائز: {winningTeams[0].name} ({winningTeams[0].score} نقطة)
        </p>
      ) : (
        <div>
          <h3 className="text-2xl font-bold text-yellow-600">الفائزون:</h3>
          {winningTeams.map(team => (
            <p key={team.id} className="text-xl text-yellow-500">
              {team.name} ({team.score} نقطة)
            </p>
          ))}
        </div>
      )}
      <div className="mt-4">
        <h3 className="text-xl font-semibold text-yellow-600">باقي الفرق:</h3>
        {localGameData.teams
          .filter(team => !winningTeams.some(w => w.id === team.id))
          .map(team => (
            <p key={team.id} className="text-lg text-yellow-500">
              {team.name} ({team.score} نقطة)
            </p>
          ))}
      </div>
      <p className="mt-4 text-sm text-gray-700">
        سيتم إعادة توجيهك إلى الصفحة الرئيسية قريباً...
      </p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/')}
          className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-8 py-3 rounded-full text-lg font-medium shadow-lg transform hover:scale-105 transition-transform duration-300"
        >
          العودة للصفحة الرئيسية
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Game;
