import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../services/api'; // Adjust the import path as needed

const StripeCheckout: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const packageId = queryParams.get('package');

  useEffect(() => {
    const createCheckoutSession = async () => {
      if (!packageId) {
        console.error('Package ID is missing');
        return;
      }
      try {
        const response = await api.post('/payments/create-checkout-session/', {
          packageId,
        });
        if (response.data && response.data.url) {
          // Redirect the browser to the Stripe checkout page
          window.location.href = response.data.url;
        } else {
          console.error('No checkout URL returned', response.data);
        }
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    };

    createCheckoutSession();
  }, [packageId]);

  return <div>Redirecting to checkout...</div>;
};

export default StripeCheckout;
