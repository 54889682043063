import React from 'react';
import { X } from 'lucide-react';

interface ReportModalProps {
  onClose: () => void;
  onReport: (issue: string) => void;
}

const ReportModal: React.FC<ReportModalProps> = ({ onClose, onReport }) => {
  return (
    <div className="fixed inset-0 z-[999] bg-black/50 flex items-center justify-center">
      <div className="relative rounded-xl p-6 max-w-sm w-full shadow-lg bg-gradient-to-r from-gray-50 via-white to-gray-50 animate-gradient">
        {/* Close Button (Top Left) */}
        <button 
          onClick={onClose} 
          className="absolute top-3 left-3 p-1 hover:bg-gray-200 rounded-full transition-colors"
        >
          <X className="w-5 h-5 text-gray-700" />
        </button>

        <h3 className="text-xl font-bold mb-4 text-gray-800">أبلغ عن مشكلة</h3>
        <p className="mb-4 text-gray-700">
          يرجى اختيار المشكلة التي تريد الإبلاغ عنها:
        </p>

        <div className="flex flex-col gap-3">
          <button 
            onClick={() => onReport('الإجابة غير صحيحة')}
            className="bg-red-500 hover:bg-red-600 transition-colors text-white px-4 py-2 rounded"
          >
            الإجابة غير صحيحة
          </button>
          <button 
            onClick={() => onReport('صورة/فيديو غير واضح')}
            className="bg-yellow-500 hover:bg-yellow-600 transition-colors text-gray-800 px-4 py-2 rounded"
          >
            صورة/فيديو غير واضح
          </button>
          <button 
            onClick={() => onReport('السؤال غير واضح')}
            className="bg-red-500 hover:bg-red-600 transition-colors text-white px-4 py-2 rounded"
          >
            السؤال غير واضح
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
