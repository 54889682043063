import api from '../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface UpdateGameProgressPayload {
  game_id: number;
  category_id: number;
  difficulty: 'easy' | 'medium' | 'hard';
  question_id: number;
  team_id: number;
  points_awarded: number;
}

export interface UpdateGameProgressResponse {
  detail: string;
  game_id: number;
  complete: boolean;
  teams: Array<{ 
    id: number; 
    name: string; 
    score: number;
    call_friend: boolean;
    two_answers: boolean;
    replace_question: boolean;
  }>;
  current_turn: number;
}

export const updateGameProgress = async (
  payload: UpdateGameProgressPayload
): Promise<UpdateGameProgressResponse> => {
  const res = await api.post('/game/update-game-progress/', payload);
  console.log("updateGameProgress response:", res.data); // Debug print in update view
  return res.data;
};

export const useUpdateGameProgressMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateGameProgress, {
    onSuccess: (data) => {
      // Invalidate the resume-game query to refresh game data
      queryClient.invalidateQueries(['resume-game']);
    },
    onError: (error: any) => {
      console.error("Error updating game progress:", error);
    }
  });
};
