import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useResetPassword } from '../services/mutations/auth/useResetPassword';
import toast from 'react-hot-toast';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { mutate: resetPasswordMutate, isLoading } = useResetPassword();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // You can validate password match here or let the server do it
    resetPasswordMutate(
      { token, password, confirm_password: confirmPassword },
      {
        onSuccess: () => {
          toast.success('تم إعادة تعيين كلمة المرور بنجاح. يمكنك الآن تسجيل الدخول.');
          navigate('/login');
        },
        onError: (error: any) => {
          console.error('Reset Password Error:', error);
          toast.error('حدث خطأ أثناء إعادة التعيين. تحقق من الرمز أو انتهت صلاحيته.');
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Fields for password, confirmPassword, etc. */}
      <button type="submit" disabled={isLoading}>إعادة التعيين</button>
    </form>
  );
};

export default ResetPassword;
