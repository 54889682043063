// Analytics.tsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GA_TRACKING_ID = 'G-6N1T9V48YL';

const Analytics: React.FC = () => {
  const location = useLocation();

  // Load GA scripts on mount
  useEffect(() => {
    // Create and append the gtag.js script
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    document.head.appendChild(script1);

    // Create and append the configuration script
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      // Set send_page_view to false so we can manually track page views
      gtag('config', '${GA_TRACKING_ID}', { 'send_page_view': false });
    `;
    document.head.appendChild(script2);

    // Cleanup scripts on unmount (optional)
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  // Track page views on route change
  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
};

export default Analytics;
