// src/components/QuestionScreen/QuestionCard.tsx

import React from 'react';
import { X } from 'lucide-react';

interface QuestionCardProps {
  questionPoints: number;
  formattedTime: string;
  questionText: string;
  media?: string | null;
  mediaType?: 'video' | 'image';
  onClose: () => void;
  onShowAnswer: () => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  questionPoints,
  formattedTime,
  questionText,
  media,
  mediaType,
  onClose,
  onShowAnswer,
}) => {
  return (
    <div className="rounded-2xl p-6 relative shadow-2xl border border-yellow-300 bg-white">
      <div className="flex justify-between items-center mb-4">
        {/* Points & Timer */}
        <div className="flex items-center gap-2 bg-yellow-100 px-3 py-1 rounded-full">
          <span className="text-lg font-bold text-yellow-800">
            {questionPoints} نقطة
          </span>
          <div className="w-px h-4 bg-yellow-300" />
          <span className="text-lg font-mono text-yellow-800">
            {formattedTime}
          </span>
        </div>
        <button
          onClick={onClose}
          className="p-2 hover:bg-yellow-100 rounded-full transition-colors"
        >
          <X className="w-5 h-5 text-yellow-800" />
        </button>
      </div>

      {/* Media (optional) */}
      {media && (
        <div className="mb-4">
          {mediaType === 'video' ? (
            <video
              controls
              className="w-full max-h-64 object-cover rounded-lg shadow-md"
            >
              <source src={media} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={media}
              alt="Question Media"
              className="w-full max-h-64 object-cover rounded-lg shadow-md"
            />
          )}
        </div>
      )}

      {/* Question Text */}
      <h2 className="text-xl font-bold text-center mb-4 text-yellow-800">
        {questionText}
      </h2>

      {/* Show Answer Button */}
      <div className="text-center">
        <button
          onClick={onShowAnswer}
          className="bg-yellow-500 hover:bg-yellow-600 px-6 py-3 rounded-md font-bold transition-all text-white shadow-md"
        >
          عرض الإجابة
        </button>
      </div>
    </div>
  );
};

export default QuestionCard;
