import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Navbar from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import Home from './pages/Home';
import CombinedGameSetup from './pages/CombinedGameSetup';
import Game from './pages/Game';
import Contact from './pages/Contact';
import Signup from './pages/Signup';
import Auth from './pages/Auth';
import MyGames from './pages/MyGames';
import ForgotPassword from './pages/ForgotPassword';
import TermsAndConditions from './pages/TermsAndConditions';
import BuyGames from './pages/BuyGames';
import ResetPassword from './pages/ResetPassword';
import StripeCheckout from './pages/StripeCheckout';
import QuestionScreen from './pages/QuestionScreen';
import Success from './pages/Success';
import AwardScreen from './pages/AwardScreen';
import Analytics from './components/Analytics/Analytics'; // Adjust path as needed
import { Toaster } from 'react-hot-toast';

// Create a QueryClient instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
    mutations: {},
  },
});

// Layout component to conditionally render Navbar and Footer
const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const isGameRoute = location.pathname === '/game';

  return (
    <div className="min-h-screen bg-primary-50 font-arabic flex flex-col">
      {!isGameRoute && <Navbar />}
      <div className="flex-grow">{children}</div>
      {!isGameRoute && <Footer />}
    </div>
  );
};

const AppRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/play" element={<Signup />} />
        <Route path="/award-screen" element={<AwardScreen />} />
        <Route path="/combined-game-setup" element={<CombinedGameSetup />} />
        <Route path="/game" element={<Game />} />
        <Route path="/my-games" element={<MyGames />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/success" element={<Success />} />
        <Route path="/question-screen" element={<QuestionScreen />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/buy-games" element={<BuyGames />} />
        <Route path="/stripe-checkout" element={<StripeCheckout />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {/* Analytics component should be mounted once at the top level */}
          <Analytics />
          <Toaster 
            position="top-center"
            toastOptions={{
              duration: 3000,
              style: {
                background: '#1e1b4b',
                color: '#fff',
                borderRadius: '12px',
                padding: '16px',
              },
              success: {
                iconTheme: {
                  primary: '#4ade80',
                  secondary: '#1e1b4b',
                },
              },
              error: {
                iconTheme: {
                  primary: '#ef4444',
                  secondary: '#1e1b4b',
                },
              },
            }}
          />
          <AppRoutes />
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
