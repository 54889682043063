// src/components/AwardScreen.tsx
import React, { useState } from 'react';
import { X, Info } from 'lucide-react';
import toast from 'react-hot-toast';

import ReportModal from '../components/Game/ReportModal';
import useReportQuestion from '../services/mutations/game/useReportQuestion';
import { Question, Team } from './Game';

interface AwardScreenProps {
  question: Question;
  teams: Team[];
  onAward: (teamId: number | null) => void;
  onBack: () => void; // Dismiss the award screen and return to the question screen.
  answerChosen: number | null;
  mediaAfter: string[] | null;
}

const AwardScreen: React.FC<AwardScreenProps> = ({
  question,
  teams,
  onAward,
  onBack,
  answerChosen,
  mediaAfter,
}) => {
  const [showReportModal, setShowReportModal] = useState(false);
  const reportQuestionMutation = useReportQuestion();

  const handleReport = (issue: string) => {
    reportQuestionMutation.mutate(
      { questionId: question.id, reason: issue },
      {
        onSuccess: () => {
          toast.success('تم إرسال البلاغ بنجاح');
          setShowReportModal(false);
        },
        onError: () => {
          toast.error('حدث خطأ أثناء إرسال البلاغ');
        },
      }
    );
  };

  // Back button simply dismisses AwardScreen.
  const handleBack = () => {
    onBack();
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Header */}
      <header className="flex items-center justify-between p-4 border-b border-gray-300">
        <button
          onClick={handleBack}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <X className="w-6 h-6 text-gray-800" />
        </button>
        <button
          onClick={() => setShowReportModal(true)}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <Info className="w-6 h-6 text-gray-800" />
        </button>
      </header>

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center p-8">
        <h2 className="text-3xl font-bold mb-4 text-yellow-700">نتيجة السؤال</h2>
        <p className="mb-2 text-xl text-gray-800">
          الإجابة الصحيحة:{' '}
          <span className="text-yellow-700">
            {question.answer || 'الإجابة الافتراضية'}
          </span>
        </p>

        {mediaAfter && mediaAfter.length > 0 && (
          <div className="my-4 grid grid-cols-1 gap-4">
            {mediaAfter.map((url) => (
              <div
                key={url}
                className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden"
              >
                <img
                  src={url}
                  alt="Media after answer"
                  className="w-full h-auto object-contain"
                />
              </div>
            ))}
          </div>
        )}

        <p className="mb-4 text-lg text-gray-800">
          اختر المجموعة التي تُمنح لها النقاط أو اختر "لا أحد" إذا لم يجب أحد:
        </p>
        <div className="flex flex-wrap gap-4 justify-center mb-6">
          {teams.map((team) => (
            <button
              key={team.id}
              onClick={() => onAward(team.id)}
              className="bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 transition-transform px-6 py-2 rounded-full text-white font-semibold shadow-md transform hover:scale-105"
            >
              {team.name}
            </button>
          ))}
          <button
            onClick={() => onAward(null)}
            className="bg-gray-500 hover:bg-gray-600 transition-colors px-6 py-2 rounded-full text-white font-semibold shadow-md"
          >
            لا أحد
          </button>
        </div>
      </main>

      {/* Report Modal */}
      {showReportModal && (
        <ReportModal
          onClose={() => setShowReportModal(false)}
          onReport={handleReport}
        />
      )}
    </div>
  );
};

export default AwardScreen;
