import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';

import { useResumeGameQuery } from '../../services/queries/game';
import { useUpdateGameProgressMutation } from '../../services/mutations/game/updateGameProgress';

import {
  GameData,
  Team,
  Category,
  Question,
  TeamAbilities,
} from '../../pages/Game';

type ScreenMode = 'game' | 'question' | 'award-screen';

const mergeTeams = (oldTeams: Team[], newTeams: any[]): Team[] => {
  console.debug("Merging teams:", { oldTeams, newTeams });
  const merged = newTeams.map(newTeam => {
    const oldTeam = oldTeams.find(t => t.id === newTeam.id);
    return {
      ...newTeam,
      call_friend: oldTeam ? oldTeam.call_friend : newTeam.call_friend || false,
      two_answers: oldTeam ? oldTeam.two_answers : newTeam.two_answers || false,
      replace_question: oldTeam ? oldTeam.replace_question : newTeam.replace_question || false,
    };
  });
  console.debug("Merged teams:", merged);
  return merged;
};

export function useGameLogic(passedGameData?: GameData) {
  const navigate = useNavigate();
  const { data: fetchedGameData, isLoading, isError } = useResumeGameQuery();
  const [localGameData, setLocalGameData] = useState<GameData | null>(null);

  const [currentScreen, setCurrentScreen] = useState<ScreenMode>('game');
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState<Set<number>>(new Set());
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [questionModalVisible, setQuestionModalVisible] = useState(false);
  const [answerChosen, setAnswerChosen] = useState<number | null>(null);
  const [timer, setTimer] = useState(0);

  const [teamAbilities, setTeamAbilities] = useState<Record<number, TeamAbilities>>({});
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);

  // New state for game completion
  const [gameFinished, setGameFinished] = useState(false);
  const [winningTeams, setWinningTeams] = useState<Team[]>([]);

  const { mutate: updateProgress } = useUpdateGameProgressMutation();

  // ★ Initialize local game data only once.
  useEffect(() => {
    if (!localGameData) {
      if (passedGameData) {
        setLocalGameData(passedGameData);
      } else if (fetchedGameData) {
        setLocalGameData(fetchedGameData);
      }
    }
  }, [passedGameData, fetchedGameData, localGameData]);

  // Initialize team abilities once when game data loads.
  useEffect(() => {
    if (localGameData && Object.keys(teamAbilities).length === 0) {
      const initialAbilities: Record<number, TeamAbilities> = {};
      localGameData.teams.forEach(team => {
        initialAbilities[team.id] = {
          callFriend: team.call_friend,
          twoAnswers: team.two_answers,
          replaceQuestion: team.replace_question,
        };
      });
      setTeamAbilities(initialAbilities);
    }
  }, [localGameData, teamAbilities]);

  // Scroll listener for header collapse.
  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScreen === 'game') {
        setIsHeaderCollapsed(currentScrollY > lastScrollY && currentScrollY > 100);
      }
      lastScrollY = currentScrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentScreen]);

  // Timer for question modal.
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (questionModalVisible) {
      interval = setInterval(() => setTimer(prev => prev + 1), 1000);
    }
    return () => clearInterval(interval);
  }, [questionModalVisible]);

  const closeQuestionModal = () => {
    setTimer(0);
    if (currentQuestion) {
      setSelectedQuestions(prev => {
        const newSet = new Set(prev);
        newSet.delete(currentQuestion.id);
        return newSet;
      });
    }
    setCurrentQuestion(null);
    setQuestionModalVisible(false);
    setCurrentScreen('game');
  };

  const handleQuestionCircleClick = (
    category: Category,
    points: number,
    questionId: number,
    difficulty: 'easy' | 'medium' | 'hard'
  ) => {
    setSelectedQuestions(prev => new Set(prev).add(questionId));
    const realQuestion = localGameData?.questions.find(q => q.id === questionId);
    if (!realQuestion) {
      toast.error('لم يتم العثور على السؤال');
      return;
    }
    setCurrentQuestion(realQuestion);
    setQuestionModalVisible(true);
    setTimer(0);
    setCurrentScreen('question');
  };

  const handleOptionClick = (selectedOption: string, optionIndex: number) => {
    if (!currentQuestion) return;
    setAnswerChosen(optionIndex);
    setQuestionModalVisible(false);
    setCurrentScreen('award-screen');
  };

  const handleNoOptionAnswer = () => {
    setAnswerChosen(-1);
    setQuestionModalVisible(false);
    setCurrentScreen('award-screen');
  };

  const isQuestionAnswered = (
    categoryId: number,
    difficulty: 'easy' | 'medium' | 'hard',
    qid: number
  ): boolean => {
    if (!localGameData) return false;
    const catProgress = localGameData.progress_data[String(categoryId)];
    return catProgress?.[difficulty]?.answered.includes(qid) || false;
  };

  const isGameComplete = (progressData: GameData['progress_data']) => {
    for (const catId in progressData) {
      const diffs = progressData[catId];
      for (const diff in diffs) {
        if (diffs[diff].answered.length < diffs[diff].selected.length) {
          return false;
        }
      }
    }
    return true;
  };

  const getWinningTeam = (teams: Team[]) => {
    const maxScore = Math.max(...teams.map(t => t.score));
    const winners = teams.filter(t => t.score === maxScore);
    return winners;
  };

  // Score update functions.
  const handleScoreChangeImmediate = (teamId: number, delta: number) => {
    if (!localGameData) return;
    const updatedTeams = localGameData.teams.map(team =>
      team.id === teamId ? { ...team, score: team.score + delta } : team
    );
    setLocalGameData({ ...localGameData, teams: updatedTeams });
    const team = localGameData.teams.find(t => t.id === teamId);
    if (team) {
      toast.success(`${delta >= 0 ? '+' : '-'}${Math.abs(delta)} نقطة لفريق ${team.name}`);
    }
  };

  const debouncedScoreUpdate = useCallback(
    debounce((teamId: number, delta: number) => {
      if (!localGameData) return;
      const payload = {
        game_id: localGameData.id,
        team_id: teamId,
        points_awarded: delta,
      };
      console.debug("Debounced update payload:", payload);
      updateProgress(payload, {
        onSuccess: (data) => {
          console.debug("Debounced score update success, current_turn:", data.current_turn);
        },
        onError: (error: any) => {
          toast.error('خطأ في تحديث النقاط');
        },
      });
    }, 500),
    [localGameData, updateProgress]
  );
  

  const handleScoreChange = (teamId: number, delta: number) => {
    handleScoreChangeImmediate(teamId, delta);
    debouncedScoreUpdate(teamId, delta);
  };

  // Award logic: update progress and update current turn.
  const handleAward = (teamId: number | null) => {
    if (!currentQuestion || !localGameData) return;
    const effectiveTeamId = teamId && teamId > 0 ? teamId : -1;
    const pointsAwarded =
      effectiveTeamId > 0
        ? currentQuestion.difficulty === 'easy'
          ? 300
          : currentQuestion.difficulty === 'medium'
          ? 500
          : 700
        : 0;
  
    const updatedProgressData = { ...localGameData.progress_data };
    const catIdStr = String(currentQuestion.category);
    if (updatedProgressData[catIdStr]) {
      updatedProgressData[catIdStr][currentQuestion.difficulty].answered = [
        ...updatedProgressData[catIdStr][currentQuestion.difficulty].answered,
        currentQuestion.id,
      ];
    }
  
    const payload = {
      game_id: localGameData.id,
      category_id: currentQuestion.category,
      difficulty: currentQuestion.difficulty,
      question_id: currentQuestion.id,
      team_id: effectiveTeamId,
      points_awarded: pointsAwarded,
    };
  
    console.debug("Before updateProgress, current_turn:", localGameData.current_turn);
    updateProgress(payload, {
      onSuccess: (data) => {
        console.debug("Server returned current_turn:", data.current_turn);
        const updatedTeams = mergeTeams(localGameData.teams, data.teams);
        setLocalGameData(prev =>
          prev
            ? {
                ...prev,
                teams: updatedTeams,
                current_turn: data.current_turn,
                progress_data: updatedProgressData,
              }
            : prev
        );
        console.debug("After state update, new current_turn:", data.current_turn);
  
        // Check if game is complete.
        if (isGameComplete(updatedProgressData)) {
          const winners = getWinningTeam(updatedTeams);
          setWinningTeams(winners);
          setGameFinished(true);
          // After 5 seconds, redirect to home.
          setTimeout(() => {
            navigate('/');
          }, 5000);
        }
      },
      onError: (error: any) => {
        toast.error('حدث خطأ أثناء تحديث التقدم.');
      },
    });
    setAnswerChosen(null);
    setCurrentQuestion(null);
    setCurrentScreen('game');
  };
  

  useEffect(() => {
    console.debug("localGameData updated:", localGameData);
  }, [localGameData]);

  // Derive the current team directly from localGameData.
  const currentTeam =
    localGameData?.teams[localGameData.current_turn] || (localGameData?.teams[0] as Team);

  return {
    isLoading,
    isError,
    localGameData,
    setLocalGameData,
    currentTeam,
    currentScreen,
    setCurrentScreen,
    isHeaderCollapsed,
    setIsHeaderCollapsed,
    showRulesModal,
    setShowRulesModal,
    showLeaveModal,
    setShowLeaveModal,
    currentQuestion,
    questionModalVisible,
    setQuestionModalVisible,
    closeQuestionModal,
    handleQuestionCircleClick,
    handleOptionClick,
    handleNoOptionAnswer,
    answerChosen,
    handleAward,
    teamAbilities,
    setTeamAbilities,
    handleScoreChange,
    isQuestionAnswered,
    // New returns for game finish modal
    gameFinished,
    winningTeams,
  };
}
