import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Plus, ChevronRight } from 'lucide-react';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { getAuthData } from '../services/mutations/auth/storage';
import { useCategories } from '../services/queries/categoryQueries';
import { useStartGameMutation, StartGamePayload } from '../services/mutations/game/game';

const CombinedGameSetup: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // Step 1: Teams Setup, Step 2: Category Selection
  const [step, setStep] = useState(1);
  const [teams, setTeams] = useState<string[]>(['', '']);
  const [gameName, setGameName] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [authData, setAuthData] = useState<{
    access: string | null;
    refresh: string | null;
    first_name: string | null;
    email: string | null;
    userGames?: string | null;
  } | null>(null);
  // State to control description visibility for each category.
  const [descriptionVisible, setDescriptionVisible] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    const storedAuth = getAuthData();
    setAuthData(storedAuth);
  }, []);

  const { data: categories, isLoading, error } = useCategories();
  const { mutate: startGameMutation } = useStartGameMutation();

  // TEAM SETUP FUNCTIONS
  const addTeam = () => {
    if (teams.length < 4) {
      setTeams([...teams, '']);
    }
  };

  const updateTeam = (index: number, value: string) => {
    const newTeams = [...teams];
    newTeams[index] = value;
    setTeams(newTeams);
  };

  const handleTeamsContinue = () => {
    if (!teams.every((t) => t.trim()) || teams.length < 2) {
      toast.error('يرجى إدخال أسماء جميع المجموعات (على الأقل مجموعتين).');
      return;
    }
    if (!gameName.trim()) {
      toast.error('يرجى إدخال اسم اللعبة.');
      return;
    }
    setStep(2);
  };

  const validTeams = teams.filter((t) => t.trim());
  const numberOfTeams = validTeams.length;

  // Determine how many categories are needed
  let totalCategoriesNeeded = 6; // default
  let categoriesMessage = '٣ فئات لفريقك و ٣ فئات للفريق المنافس';
  if (numberOfTeams === 3) {
    totalCategoriesNeeded = 6;
    categoriesMessage = '٢ فئات لكل فريق (٦ فئات بالإجمالي)';
  } else if (numberOfTeams === 4) {
    totalCategoriesNeeded = 8;
    categoriesMessage = '٢ فئات لكل فريق (٨ فئات بالإجمالي)';
  }

  const handleCategorySelect = (id: string) => {
    // Only allow selection if the category is complete (i.e., not incomplete)
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== id));
    } else if (selectedCategories.length < totalCategoriesNeeded) {
      setSelectedCategories([...selectedCategories, id]);
      if (selectedCategories.length + 1 === totalCategoriesNeeded) {
        toast.success('تم اختيار جميع الفئات! 🎮');
      }
    } else {
      toast.error(`لا يمكن اختيار أكثر من ${totalCategoriesNeeded} فئات`);
    }
  };

  const toggleDescription = (categoryId: number) => {
    setDescriptionVisible(prev => ({ ...prev, [categoryId]: !prev[categoryId] }));
  };

  const handleStartGame = () => {
    const payload: StartGamePayload = {
      categories: selectedCategories.map((id) => parseInt(id, 10)),
      teams: validTeams,
      name: gameName,
    };

    if (!authData?.access) {
      navigate('/auth', {
        state: {
          redirectTo: '/game',
          gameData: {
            name: gameName,
            selectedCategories,
            teams: validTeams,
            categories: categories.filter((category) =>
              selectedCategories.includes(category.id)
            ),
          },
        },
        replace: true,
      });
    } else {
      startGameMutation(payload, {
        onSuccess: (data) => {
          let currentGames = localStorage.getItem('userGames');
          if (currentGames) {
            const newGames = parseInt(currentGames, 10) - 1;
            localStorage.setItem('userGames', newGames.toString());
          }
          window.dispatchEvent(new Event('storage'));
          setAuthData((prev) =>
            prev ? { ...prev, userGames: localStorage.getItem('userGames') } : prev
          );
          navigate('/game', { state: { gameData: data } });
        },
        onError: (error: any) => {
          console.error('Start game error:', error);
          toast.error('حدث خطأ أثناء بدء اللعبة.');
        },
      });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center text-yellow-800 text-xl">
        جاري التحميل...
      </div>
    );
  }
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center text-yellow-800 text-xl">
        حدث خطأ أثناء تحميل الفئات. حاول مرة أخرى لاحقاً.
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <html lang="ar" dir="rtl" />
        <title>Jawb - إعداد اللعبة وتحديد الفرق والفئات</title>
        <meta
          name="description"
          content="انضم إلى Jawb، لعبة الأسئلة والأجوبة التفاعلية والممتعة! قم بإعداد الفرق واختيار الفئات لتحدي معلوماتك ومهاراتك في لعبة Q&A فريدة."
        />
        <meta name="keywords" content="Jawb, لعبة, أسئلة, أجوبة, Q&A, لعبة ثقافية, مسابقات, تفاعلية" />
        <link rel="canonical" href="https://www.jawb.com/combined-game-setup" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Jawb - إعداد اللعبة",
              "description": "Jawb is an interactive Q&A game where players set up teams and choose categories to challenge their knowledge.",
              "url": "https://www.jawb.com/combined-game-setup"
            }
          `}
        </script>
      </Helmet>
      <main className="min-h-screen bg-white text-yellow-800">
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          {step === 1 && (
            <section className="bg-white rounded-2xl p-8 shadow-lg border border-yellow-300">
              <h2 className="text-2xl font-bold text-center mb-8 text-yellow-800">
                إعداد المجموعات
              </h2>
              <div className="mb-8">
                <label
                  className="block text-center text-lg font-semibold mb-2"
                  htmlFor="gameName"
                >
                  اسم اللعبة
                </label>
                <input
                  id="gameName"
                  type="text"
                  value={gameName}
                  onChange={(e) => setGameName(e.target.value)}
                  className="w-full p-3 bg-yellow-50 border border-yellow-300 rounded-xl placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-colors"
                  placeholder="أدخل اسم اللعبة"
                />
              </div>
              <div className="flex flex-wrap items-center gap-4 justify-center">
                {teams.map((team, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <span className="mb-2 font-semibold text-yellow-600">
                      المجموعة #{index + 1}
                    </span>
                    <input
                      type="text"
                      className="w-48 p-3 bg-yellow-50 border border-yellow-300 rounded-xl placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-colors"
                      placeholder={`اسم المجموعة ${index + 1}`}
                      value={team}
                      onChange={(e) => updateTeam(index, e.target.value)}
                    />
                    {teams.length > 2 && (
                      <button
                        onClick={() => setTeams(teams.filter((_, i) => i !== index))}
                        className="mt-2 text-gray-400 hover:text-red-400 transition-colors"
                      >
                        ✕
                      </button>
                    )}
                  </div>
                ))}
                {teams.length < 4 && (
                  <button
                    onClick={addTeam}
                    className="flex items-center gap-1 px-4 py-2 rounded-xl text-yellow-700 hover:text-yellow-800 transition-colors border border-yellow-300 bg-yellow-50 hover:bg-yellow-100"
                  >
                    <Plus className="w-5 h-5" />
                    إضافة مجموعة
                  </button>
                )}
              </div>
              <div className="mt-10">
                <button
                  onClick={handleTeamsContinue}
                  disabled={!teams.every((t) => t.trim()) || teams.length < 2 || !gameName.trim()}
                  className="w-full flex items-center justify-center bg-gradient-to-r from-yellow-500 to-yellow-600 text-white py-3 rounded-xl font-bold hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  التالي <ChevronRight className="w-5 h-5 ml-2" />
                </button>
              </div>
            </section>
          )}

          {step === 2 && (
            <section className="bg-white rounded-2xl p-6 border border-yellow-300 shadow-lg">
              <h2 className="text-4xl font-bold text-center mb-4 text-yellow-800">
                اختر الفئات
              </h2>
              <p className="text-center mb-6 text-yellow-700">{categoriesMessage}</p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {categories.map((category) => (
                  <div key={category.id} className="relative">
                    <button
                      onClick={() => handleCategorySelect(category.id)}
                      disabled={category.is_incomplete}
                      className={`
                        bg-white rounded-2xl border border-yellow-300
                        transition-all transform hover:scale-105 hover:shadow-lg
                        ${selectedCategories.includes(category.id) ? 'ring-2 ring-yellow-500' : ''}
                        ${category.is_incomplete ? 'opacity-30 cursor-not-allowed' : ''}
                        overflow-hidden group w-full
                      `}
                    >
                      {category.media && (
                        <div className="relative">
                          <img
                            src={category.media}
                            alt={category.name}
                            className="w-full h-40 object-cover"
                          />
                          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2">
                            <h3 className="text-xl font-bold text-white text-center">
                              {category.name}
                            </h3>
                          </div>
                        </div>
                      )}
                    </button>
                    <div className="mt-2 text-center">
                      <button
                        onClick={() => toggleDescription(category.id)}
                        className="text-sm text-yellow-600 hover:underline"
                      >
                        {descriptionVisible[category.id] ? 'إخفاء الوصف' : 'عرض الوصف'}
                      </button>
                      {descriptionVisible[category.id] && (
                        <div className="mt-1 p-2 border border-gray-300 rounded bg-gray-50 text-xs text-gray-700">
                          {category.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {selectedCategories.length === totalCategoriesNeeded && (
                <div className="mt-12 text-center">
                  <button
                    onClick={handleStartGame}
                    className="px-12 py-4 rounded-xl text-xl font-bold transition-all transform bg-gradient-to-r from-yellow-500 to-yellow-600 text-white hover:scale-105"
                  >
                    ابدأ اللعب
                  </button>
                </div>
              )}
            </section>
          )}
        </div>
      </main>
    </>
  );
};

export default CombinedGameSetup;
