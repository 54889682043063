import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { useCategories } from '../../services/queries/categoryQueries';

interface Category {
  id: number;
  name: string;
  media: string; // updated field name
}

const CategoriesSlider: React.FC = () => {
  const { data, isLoading, error } = useCategories();
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    // Custom autoplay: moves two slides at a time.
    const interval = setInterval(() => {
      sliderRef.current?.slickNext();
      setTimeout(() => {
        sliderRef.current?.slickNext();
      }, 200);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) return <p className="text-center text-yellow-700">جارٍ التحميل...</p>;
  if (error) return <p className="text-center text-yellow-700">حدث خطأ أثناء تحميل الفئات.</p>;

  // Assuming data is an array of categories
  const categories: Category[] = data;
  const displayedCategories = categories.slice(0, 8);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="py-12">
      <h2 className="text-4xl md:text-5xl font-bold text-center mb-8 text-yellow-700">
        فئاتنا
      </h2>
      <Slider ref={sliderRef} {...settings}>
        {displayedCategories.map((category) => (
          <div key={category.id} className="px-2">
            <button
              onClick={() => {
                /* handle category selection or navigation if needed */
              }}
              className="relative bg-white rounded-2xl border border-yellow-300 transition-all transform hover:scale-105 hover:shadow-lg overflow-hidden group w-full"
            >
              {category.media && (
                <div className="relative">
                  <img
                    src={category.media}
                    alt={category.name}
                    className="w-full h-40 object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2">
                    <h3 className="text-xl font-bold text-white text-center">
                      {category.name}
                    </h3>
                  </div>
                </div>
              )}
            </button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CategoriesSlider;
