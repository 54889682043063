import api from '../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface PurchasePackagePayload {
  package_id: number;
  stripe_session_id: string;
}

export interface PurchasePackageResponse {
  detail: string;
  success: boolean;
}

// API call to your backend endpoint to process the purchase
export const purchasePackage = async (
  payload: PurchasePackagePayload
): Promise<PurchasePackageResponse> => {
  const res = await api.post('/payments/purchase-package/', payload);
  console.log("purchasePackage response:", res.data);
  return res.data;
};

// Custom hook to use the purchase package mutation
export const usePurchasePackageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(purchasePackage, {
    onSuccess: (data) => {
      // Optionally, invalidate queries to refresh user data (e.g. games)
      queryClient.invalidateQueries(['user-games']);
    },
    onError: (error: any) => {
      console.error("Error processing purchase:", error);
    }
  });
};
