import React from 'react';
import { Phone, ListChecks, RefreshCcw } from 'lucide-react';
import { Team, TeamAbilities } from '../../pages/Game';

interface TeamCornerProps {
  team: Team;
  index: number;
  isCurrent: boolean;
  onScoreChange: (teamId: number, delta: number) => void;
  teamAbilities?: Record<number, TeamAbilities>;
  expanded?: boolean;
  onAbilityClick?: (teamId: number, ability: 'callFriend' | 'twoAnswers' | 'replaceQuestion') => void;
}

const TeamCorner: React.FC<TeamCornerProps> = ({
  team,
  index,
  isCurrent,
  onScoreChange,
  teamAbilities,
  expanded = false,
  onAbilityClick,
}) => {
  const abilities =
    teamAbilities && teamAbilities[team.id]
      ? teamAbilities[team.id]
      : {
          callFriend: false,
          twoAnswers: false,
          replaceQuestion: false,
        };

  const callFriendDisabled = !isCurrent || abilities.callFriend;
  const twoAnswersDisabled = !isCurrent || abilities.twoAnswers;
  // const replaceDisabled = !isCurrent || abilities.replaceQuestion; // "replace question" is currently disabled

  const handleScoreChange = (delta: number) => {
    onScoreChange(team.id, delta);
  };

  const handleAbilityClick = (ability: 'callFriend' | 'twoAnswers' | 'replaceQuestion') => {
    if (onAbilityClick) {
      onAbilityClick(team.id, ability);
    }
  };

  return (
    <div
      className={`
        relative bg-white rounded-xl p-3 mx-auto w-full
        ${isCurrent ? 'ring-2 ring-orange-500' : ''}
        ${expanded ? 'md:w-[300px]' : 'md:w-[200px]'}
      `}
    >
      {isCurrent && (
        <div className="absolute -top-3 right-2 bg-orange-500 text-white text-xs px-2 py-1 rounded-full">
          دوركم الآن
        </div>
      )}
      <div className="mb-2 text-center">
        <h2 className="text-xs font-medium text-gray-800 truncate">{team.name}</h2>
      </div>
      <div className="flex items-center justify-between bg-gray-100 rounded-lg px-2 py-1 mb-2">
        <button
          onClick={() => handleScoreChange(-100)}
          className="text-orange-800 hover:text-orange-900 transition-colors text-base"
        >
          −
        </button>
        <span className="text-sm font-semibold text-gray-800 mx-1">{team.score}</span>
        <button
          onClick={() => handleScoreChange(100)}
          className="text-orange-800 hover:text-orange-900 transition-colors text-base"
        >
          +
        </button>
      </div>
      <div className="flex justify-center gap-2 flex-wrap">
        <button
          onClick={() => handleAbilityClick('callFriend')}
          className={`p-2 rounded-md flex items-center ${
            abilities.callFriend ? 'bg-orange-200' : 'bg-orange-50 hover:bg-orange-100'
          }`}
          disabled={callFriendDisabled}
        >
          <Phone className="w-4 h-4 text-orange-600" />
          <span className="ml-1 text-xs">{abilities.callFriend ? 'مستخدم' : 'اتصال'}</span>
        </button>
        <button
          onClick={() => handleAbilityClick('twoAnswers')}
          className={`p-2 rounded-md flex items-center ${
            abilities.twoAnswers ? 'bg-orange-200' : 'bg-orange-50 hover:bg-orange-100'
          }`}
          disabled={twoAnswersDisabled}
        >
          <ListChecks className="w-4 h-4 text-orange-600" />
          <span className="ml-1 text-xs">{abilities.twoAnswers ? 'مستخدم' : 'إجابتين'}</span>
        </button>
        {/*
        <button
          onClick={() => handleAbilityClick('replaceQuestion')}
          className={`p-2 rounded-md flex items-center ${
            abilities.replaceQuestion ? 'bg-orange-200' : 'bg-orange-50 hover:bg-orange-100'
          }`}
          disabled={!isCurrent || abilities.replaceQuestion}
        >
          <RefreshCcw className="w-4 h-4 text-orange-600" />
          <span className="ml-1 text-xs">{abilities.replaceQuestion ? 'مستخدم' : 'استبدال'}</span>
        </button>
        */}
      </div>
    </div>
  );
};

export default TeamCorner;
