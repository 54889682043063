import api from '../api';
import { useQuery } from '@tanstack/react-query';

export interface Package {
  id: number;
  name: string;
  description: string;
  price: number;
  games_count: number;
}

// Function to fetch packages from your backend endpoint
export const fetchPackages = async (): Promise<Package[]> => {
  const res = await api.get('/payments/packages/');
  // Assuming your backend returns an object like: { packages: [...] }
  return res.data.packages;
};

// Custom hook to use the packages query
export const usePackages = () => {
  return useQuery<Package[], Error>(['packages'], fetchPackages);
};
