import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="min-h-screen bg-white text-yellow-800 overflow-y-auto">
      <main className="container mx-auto px-6 py-8">
        <div className="bg-white rounded-2xl shadow-lg border border-yellow-300 p-6">
          <h1 className="text-3xl font-bold text-center mb-6">
            الشروط والأحكام وسياسة الخصوصية
          </h1>
          <p className="mb-4">
            مرحباً بكم في تطبيق Jawb، لعبة الأسئلة والأجوبة التفاعلية. باستخدامك لهذا التطبيق، فإنك توافق على الالتزام بالشروط والأحكام وسياسة الخصوصية التالية. يرجى قراءة هذه الوثيقة بعناية، حيث تُعتبر موافقتك على هذه الشروط دليلاً على موافقتك الكاملة.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">1. الاستخدام المسموح به</h2>
          <p className="mb-4">
            يُسمح باستخدام التطبيق للأغراض الشخصية والتعليمية والترفيهية فقط. يُمنع استخدام التطبيق لأي أغراض تجارية أو غير قانونية دون الحصول على إذن مسبق.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">2. الملكية الفكرية</h2>
          <p className="mb-4">
            جميع المحتويات، بما في ذلك النصوص، الصور، الشعارات، والعلامات التجارية، هي ملك لأصحابها وتُعتبر محمية بموجب قوانين حقوق النشر والعلامات التجارية. يُمنع إعادة إنتاج أو توزيع أو تعديل أي جزء من محتويات التطبيق دون إذن خطي مسبق.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">3. سياسة الخصوصية</h2>
          <p className="mb-4">
            نلتزم بحماية خصوصيتك وفقًا لأفضل الممارسات والقوانين المعمول بها. نقوم بجمع معلوماتك الشخصية (مثل الاسم والبريد الإلكتروني) لأغراض تحسين الخدمة وتقديم تجربة مستخدم أفضل. لن نقوم بمشاركة معلوماتك مع أطراف ثالثة دون موافقتك، إلا إذا استدعى القانون ذلك.
          </p>
          <p className="mb-4">
            يمكنك قراءة سياسة الخصوصية الكاملة الخاصة بنا لمعرفة المزيد عن كيفية جمع واستخدام وتخزين بياناتك.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">4. التعديلات على الشروط</h2>
          <p className="mb-4">
            يحتفظ التطبيق بالحق في تعديل هذه الشروط والأحكام في أي وقت دون إشعار مسبق. يُعتبر استمرار استخدامك للتطبيق بعد نشر التعديلات موافقة منك على الشروط المعدلة.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">5. إخلاء المسؤولية</h2>
          <p className="mb-4">
            يُقدم التطبيق "كما هو" دون أي ضمانات صريحة أو ضمنية. لا نتحمل أي مسؤولية عن الأضرار المباشرة أو غير المباشرة التي قد تنشأ نتيجة لاستخدام التطبيق أو عدم قدرته على الأداء بالشكل المطلوب.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">6. تسوية المنازعات والقانون الواجب التطبيق</h2>
          <p className="mb-4">
            تخضع هذه الشروط والأحكام للقوانين المعمول بها في الدولة. يتم تسوية أي نزاعات تنشأ عن استخدام التطبيق وفقاً لهذه القوانين وفي المحاكم المختصة.
          </p>
          
          <h2 className="text-2xl font-bold mt-6 mb-4">7. سياسة الاسترداد</h2>
          <p className="mb-4">
            جميع عمليات الشراء داخل التطبيق نهائية ولا يحق للمستخدم طلب استرداد الأموال، ما لم يكن هناك خطأ تقني أو خلل في الخدمة يتم إثباته وتقديمه وفق الإجراءات المحددة قانونياً.
          </p>
          
          <p className="mt-6 text-sm text-center text-gray-500">
            © 2025 Jawb. جميع الحقوق محفوظة.
          </p>
        </div>
      </main>
    </div>
  );
};

export default TermsAndConditions;
