import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BookOpen, ArrowLeft, ChevronUp, ChevronDown } from 'lucide-react';
import logo from '../../assets/logo/logoWhite.png';

interface HeaderProps {
  gameName: string;
  onShowRules: () => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  // This header is used only in the game screen.
}

const Header: React.FC<HeaderProps> = ({
  gameName,
  onShowRules,
  isCollapsed,
  onToggleCollapse,
}) => {
  const navigate = useNavigate();
  const [showLeaveModal, setShowLeaveModal] = React.useState(false);

  const handleBackClick = () => {
    // In game screen, open leave-game confirmation.
    setShowLeaveModal(true);
  };

  const confirmLeaveGame = () => {
    setShowLeaveModal(false);
    navigate('/'); // Adjust exit route as needed.
  };

  const cancelLeaveGame = () => setShowLeaveModal(false);

  return (
    <>
      {/* Collapsible Header Wrapper */}
      <div
        className="fixed top-0 left-0 right-0 z-50 transition-transform duration-300"
        style={{ transform: isCollapsed ? 'translateY(-100%)' : 'translateY(0)' }}
      >
        <header className="bg-yellow-500 py-4 px-4 shadow-lg">
          <div className="max-w-6xl mx-auto flex items-center justify-center relative">
            {/* Left Buttons Group */}
            <div className="absolute left-0 flex gap-3">
              <button
                onClick={onShowRules}
                className="p-2 rounded-full transition-colors text-white hover:text-yellow-200"
                title="Rules"
              >
                <BookOpen className="w-6 h-6" />
              </button>
              <button
                onClick={onToggleCollapse}
                className="p-2 rounded-full transition-colors text-white hover:text-yellow-200"
                title="Toggle Header"
              >
                {isCollapsed ? (
                  <ChevronDown className="w-6 h-6" />
                ) : (
                  <ChevronUp className="w-6 h-6" />
                )}
              </button>
              <button
                onClick={handleBackClick}
                className="p-2 rounded-full transition-colors text-white hover:text-yellow-200"
                title="Back"
              >
                <ArrowLeft className="w-6 h-6" />
              </button>
            </div>
            {/* Centered Title (smaller font, refined) */}
            <h1 className="text-center text-lg sm:text-xl font-medium text-white font-sans">
              {gameName}
            </h1>
            {/* Logo on the right */}
            <div className="absolute right-0">
              <img src={logo} alt="Logo" className="w-18 h-18" />
            </div>
          </div>
        </header>
      </div>

      {/* Always-visible Pull Tab when Header is Collapsed */}
      {isCollapsed && (
        <div className="fixed top-0 left-0 right-0 z-40 flex justify-center">
          <button
            onClick={onToggleCollapse}
            className="mt-2 flex items-center justify-center w-12 h-12 rounded-full bg-gradient-to-r from-yellow-600 to-yellow-400 shadow-xl transform hover:scale-110 transition-transform"
            title="Show Header"
          >
            <ChevronDown className="w-6 h-6 text-white" />
          </button>
        </div>
      )}

      {/* Leave Game Confirmation Modal */}
      {showLeaveModal && (
        <div className="fixed inset-0 bg-black/75 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-6 max-w-md w-full shadow-2xl border border-yellow-300">
            <h2 className="text-2xl font-bold mb-4 text-center text-yellow-800">
              هل أنت متأكد أنك تريد مغادرة اللعبة؟
            </h2>
            <div className="flex justify-around">
              <button
                onClick={confirmLeaveGame}
                className="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-full text-white font-bold"
              >
                نعم
              </button>
              <button
                onClick={cancelLeaveGame}
                className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-full text-yellow-800 font-bold"
              >
                لا
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
