import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail } from 'lucide-react';
import toast from 'react-hot-toast';
import { useForgotPassword } from '../services/mutations/auth/useForgotPassword';
import { Helmet } from 'react-helmet';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { mutate: forgotPasswordMutate, isLoading } = useForgotPassword();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    forgotPasswordMutate(
      { email },
      {
        onSuccess: () => {
          toast.success("تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني.");
          navigate('/'); // Go to Home page on success
        },
        onError: (err: any) => {
          console.error('Forgot password error:', err);
          toast.error("حدث خطأ أثناء إرسال البريد الإلكتروني. حاول مرة أخرى.");
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <html lang="ar" dir="rtl" />
        <title>Jawb - إعادة تعيين كلمة المرور</title>
        <meta
          name="description"
          content="إعادة تعيين كلمة المرور في Jawb، لعبة الأسئلة والأجوبة التفاعلية. أدخل بريدك الإلكتروني لاستلام رابط إعادة التعيين."
        />
        <meta name="keywords" content="Jawb, إعادة تعيين, كلمة المرور, forgot password, Q&A, لعبة, أسئلة, أجوبة" />
        <link rel="canonical" href="https://www.jawb.com/forgot-password" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Jawb - إعادة تعيين كلمة المرور",
              "description": "صفحة إعادة تعيين كلمة المرور في Jawb، لعبة الأسئلة والأجوبة التفاعلية."
            }
          `}
        </script>
      </Helmet>
      <main className="min-h-[calc(100vh-80px)] bg-white flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-2xl p-8 border border-yellow-300 mx-4 shadow-lg">
          <h2 className="text-3xl font-bold text-center mb-8 text-yellow-800">
            إعادة تعيين كلمة المرور
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="group">
              <label className="block text-gray-900/80 mb-2">البريد الإلكتروني</label>
              <div className="flex items-center bg-yellow-50 border border-yellow-300 rounded-xl p-3">
                <Mail className="text-yellow-600 ml-2" size={20} />
                <input
                  type="email"
                  className="w-full bg-transparent text-gray-900 placeholder-gray-400 outline-none"
                  placeholder="أدخل بريدك الإلكتروني"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-yellow-500 to-yellow-600 text-white py-4 rounded-2xl font-bold hover:opacity-90 transition-opacity"
              disabled={isLoading}
            >
              {isLoading ? 'جاري الإرسال...' : 'إرسال الرابط'}
            </button>
            <p className="text-center text-gray-900/70">
              هل تذكرت كلمة المرور؟{' '}
              <button
                type="button"
                onClick={() => navigate('/auth')}
                className="text-yellow-600 hover:text-yellow-700 font-medium"
              >
                تسجيل الدخول
              </button>
            </p>
          </form>
        </div>
      </main>
    </>
  );
};

export default ForgotPassword;
