// src/components/QuestionScreen/CategoriesBoard.tsx
import React from 'react';
import TeamCorner from './TeamCorner';
import { Category, Team, TeamAbilities } from '../../pages/Game';

interface CategoriesBoardProps {
  categories: Category[];
  progressData: Record<
    string,
    {
      easy: { selected: number[]; answered: number[] };
      medium: { selected: number[]; answered: number[] };
      hard: { selected: number[]; answered: number[] };
    }
  >;
  onQuestionClick: (
    category: Category,
    points: number,
    questionId: number,
    difficulty: 'easy' | 'medium' | 'hard'
  ) => void;
  isQuestionAnswered: (
    categoryId: number,
    difficulty: 'easy' | 'medium' | 'hard',
    qid: number
  ) => boolean;
  teams: Team[];
  currentTeam: Team;
  onScoreChange: (teamId: number, delta: number) => void;
  teamAbilities?: Record<number, TeamAbilities>;
}

interface QuestionSegment {
  difficulty: 'easy' | 'medium' | 'hard';
  qid: number;
  pointsValue: number;
}

const toArabicNumerals = (num: number): string => {
  const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  return String(num)
    .split('')
    .map((char) => (/\d/.test(char) ? arabicDigits[parseInt(char)] : char))
    .join('');
};

const getSegmentClasses = (answered: boolean): string =>
  answered
    ? 'bg-yellow-200 text-yellow-800'
    : 'bg-yellow-500 text-white hover:bg-yellow-600';

const QuestionButton: React.FC<{
  segment: QuestionSegment;
  answered: boolean;
  onClick: () => void;
  borderNeeded: boolean;
}> = ({ segment, answered, onClick, borderNeeded }) => {
  const handleClick = () => {
    if (!answered) onClick();
  };
  const segmentClasses = getSegmentClasses(answered);
  const borderClasses = borderNeeded ? 'border-t border-gray-400' : '';
  return (
    <div
      onClick={handleClick}
      className={`flex-1 flex items-center justify-center text-2xl font-semibold cursor-pointer select-none transition-transform duration-200 active:scale-95 hover:scale-105 rounded-md shadow-sm hover:shadow-md ${segmentClasses} ${borderClasses}`}
    >
      {toArabicNumerals(segment.pointsValue)}
    </div>
  );
};

const CategoriesBoard: React.FC<CategoriesBoardProps> = ({
  categories,
  progressData,
  onQuestionClick,
  isQuestionAnswered,
  teams,
  currentTeam,
  onScoreChange,
  teamAbilities,
}) => {
  // Determine grid classes based on the total number of categories.
  // For 6 categories, we want 3 columns on medium screens.
  // For 8 categories, we want 4 columns on medium screens.
  const gridCols =
    categories.length === 6
      ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
      : categories.length === 8
      ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-4"
      : "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4";

  return (
    <div className="w-full bg-white pt-4 pb-8">
      <div className="w-full px-4">
        <div className={`grid gap-4 sm:gap-6 ${gridCols} justify-items-center`}>
          {categories.map((category) => {
            const catData = progressData[String(category.id)];
            if (!catData) return null;
            const { easy, medium, hard } = catData;

            // Build left and right column segments.
            const leftColumn: QuestionSegment[] = [];
            if (easy.selected?.[0] !== undefined) {
              leftColumn.push({ difficulty: 'easy', qid: easy.selected[0], pointsValue: 300 });
            }
            if (medium.selected?.[0] !== undefined) {
              leftColumn.push({ difficulty: 'medium', qid: medium.selected[0], pointsValue: 500 });
            }
            if (hard.selected?.[0] !== undefined) {
              leftColumn.push({ difficulty: 'hard', qid: hard.selected[0], pointsValue: 700 });
            }
            const rightColumn: QuestionSegment[] = [];
            if (easy.selected?.[1] !== undefined) {
              rightColumn.push({ difficulty: 'easy', qid: easy.selected[1], pointsValue: 300 });
            }
            if (medium.selected?.[1] !== undefined) {
              rightColumn.push({ difficulty: 'medium', qid: medium.selected[1], pointsValue: 500 });
            }
            if (hard.selected?.[1] !== undefined) {
              rightColumn.push({ difficulty: 'hard', qid: hard.selected[1], pointsValue: 700 });
            }

            return (
              <div
                key={category.id}
                className="rounded-lg border border-gray-300 shadow-sm bg-white overflow-hidden flex flex-col"
              >
                <div className="flex flex-1">
                  <div className="flex flex-col justify-between w-1/4">
                    {leftColumn.map((seg, idx) => (
                      <QuestionButton
                        key={`${seg.difficulty}-${seg.qid}`}
                        segment={seg}
                        answered={isQuestionAnswered(category.id, seg.difficulty, seg.qid)}
                        onClick={() =>
                          onQuestionClick(category, seg.pointsValue, seg.qid, seg.difficulty)
                        }
                        borderNeeded={idx > 0}
                      />
                    ))}
                  </div>
                  <div className="w-1/2 relative">
                    {category.media && (
                      <div className="w-full aspect-square">
                        <img
                          src={category.media}
                          alt={category.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <div className="absolute bottom-0 left-0 right-0 text-center text-xs md:text-sm text-white bg-black bg-opacity-50 px-2 py-1">
                      {category.name}
                    </div>
                  </div>
                  <div className="flex flex-col justify-between w-1/4">
                    {rightColumn.map((seg, idx) => (
                      <QuestionButton
                        key={`${seg.difficulty}-${seg.qid}`}
                        segment={seg}
                        answered={isQuestionAnswered(category.id, seg.difficulty, seg.qid)}
                        onClick={() =>
                          onQuestionClick(category, seg.pointsValue, seg.qid, seg.difficulty)
                        }
                        borderNeeded={idx > 0}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Teams Grid */}
      {teams.length === 2 ? (
        <div className="w-full px-4 mt-12 flex justify-between">
          {teams.map((team) => (
            <TeamCorner
              key={team.id}
              team={team}
              index={0}
              isCurrent={team.id === currentTeam.id}
              onScoreChange={onScoreChange}
              teamAbilities={teamAbilities}
              expanded={true}
            />
          ))}
        </div>
      ) : teams.length === 3 ? (
        <div className="w-full px-4 mt-12 flex justify-center gap-8">
          {teams.map((team) => (
            <TeamCorner
              key={team.id}
              team={team}
              index={0}
              isCurrent={team.id === currentTeam.id}
              onScoreChange={onScoreChange}
              teamAbilities={teamAbilities}
              expanded={false}
            />
          ))}
        </div>
      ) : (
        <div className="w-full px-4 mt-12 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 justify-items-center">
          {teams.map((team) => (
            <TeamCorner
              key={team.id}
              team={team}
              index={0}
              isCurrent={team.id === currentTeam.id}
              onScoreChange={onScoreChange}
              teamAbilities={teamAbilities}
              expanded={false}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoriesBoard;
