import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Mail, Lock, User } from 'lucide-react';
import { useLoginUser } from '../services/mutations/auth/useLoginUser';
import { useSignupUser } from '../services/mutations/auth/useSignupUser';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle between login and signup modes
  const [isLogin, setIsLogin] = useState(true);

  // Common fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Signup-specific fields
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Terms & Conditions acceptance state (for signup)
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Mutation hooks
  const { mutate: loginMutate, isLoading: loginLoading } = useLoginUser();
  const { mutate: signupMutate, isLoading: signupLoading } = useSignupUser();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isLogin) {
      // Login flow
      loginMutate(
        { email, password },
        {
          onSuccess: () => {
            toast.success('تم تسجيل الدخول بنجاح');
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userEmail', email);
            window.dispatchEvent(new Event('storage'));
            navigate(
              (location.state as any)?.redirectTo || '/',
              { state: (location.state as any)?.gameData }
            );
          },
          onError: (err: any) => {
            console.error('Login error:', err);
            toast.error('فشل تسجيل الدخول. تأكد من صحة بيانات الاعتماد.');
          },
        }
      );
    } else {
      // Signup flow: Check if passwords match
      if (password !== confirmPassword) {
        toast.error('كلمة المرور غير متطابقة');
        return;
      }
      if (!termsAccepted) {
        toast.error('يرجى الموافقة على الشروط والأحكام وسياسة الخصوصية');
        return;
      }

      signupMutate(
        { first_name: name, email, password, confirm_password: confirmPassword, terms_accepted: termsAccepted },
        {
          onSuccess: () => {
            toast.success('تم إنشاء الحساب بنجاح');
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userEmail', email);
            window.dispatchEvent(new Event('storage'));
            navigate(
              (location.state as any)?.redirectTo || '/',
              { state: (location.state as any)?.gameData }
            );
          },
          onError: (err: any) => {
            console.error('Signup error:', err);
            toast.error('فشل التسجيل. الرجاء المحاولة مرة أخرى.');
          },
        }
      );
    }
  };

  const isLoading = isLogin ? loginLoading : signupLoading;

  return (
    <>
      <Helmet>
        <html lang="ar" dir="rtl" />
        <title>Jawb - لعبة أسئلة وأجوبة | {isLogin ? 'تسجيل الدخول' : 'إنشاء حساب'}</title>
        <meta
          name="description"
          content="انضم إلى Jawb، لعبة الأسئلة والأجوبة التفاعلية التي تختبر معلوماتك بطريقة ممتعة وتنافسية. قم بالتسجيل أو تسجيل الدخول وابدأ التحدي الآن!"
        />
        <link rel="canonical" href="https://www.jawb.com/auth" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Jawb",
              "url": "https://www.jawb.com",
              "description": "Jawb is an interactive Q&A game that challenges your knowledge in a fun and competitive way."
            }
          `}
        </script>
      </Helmet>
      <main className="min-h-[calc(100vh-80px)] bg-white flex items-center justify-center p-4">
        <div className="w-full max-w-md bg-white rounded-2xl p-8 border border-yellow-300 mx-4 shadow-lg">
          <h1 className="text-3xl font-bold text-center mb-8 text-yellow-800">
            {isLogin ? 'تسجيل الدخول' : 'إنشاء حساب'}
          </h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            {!isLogin && (
              <div className="group">
                <label className="block text-gray-900/80 mb-2">الاسم الكامل</label>
                <div className="flex items-center bg-yellow-50 border border-yellow-300 rounded-xl p-3">
                  <User className="text-yellow-600 ml-2" size={20} aria-hidden="true" />
                  <input
                    type="text"
                    className="w-full bg-transparent text-gray-900 placeholder-gray-400 outline-none"
                    placeholder="أدخل اسمك الكامل"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>
            )}

            <div className="group">
              <label className="block text-gray-900/80 mb-2">البريد الإلكتروني</label>
              <div className="flex items-center bg-yellow-50 border border-yellow-300 rounded-xl p-3">
                <Mail className="text-yellow-600 ml-2" size={20} aria-hidden="true" />
                <input
                  type="email"
                  className="w-full bg-transparent text-gray-900 placeholder-gray-400 outline-none"
                  placeholder="أدخل بريدك الإلكتروني"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="group">
              <label className="block text-gray-900/80 mb-2">كلمة المرور</label>
              <div className="flex items-center bg-yellow-50 border border-yellow-300 rounded-xl p-3">
                <Lock className="text-yellow-600 ml-2" size={20} aria-hidden="true" />
                <input
                  type="password"
                  className="w-full bg-transparent text-gray-900 placeholder-gray-400 outline-none"
                  placeholder="أدخل كلمة المرور"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {isLogin && (
                <div className="mt-2 text-right">
                  <Link to="/forgot-password" className="text-sm text-yellow-600 hover:underline">
                    نسيت كلمة المرور؟
                  </Link>
                </div>
              )}
            </div>

            {!isLogin && (
              <>
                <div className="group">
                  <label className="block text-gray-900/80 mb-2">تأكيد كلمة المرور</label>
                  <div className="flex items-center bg-yellow-50 border border-yellow-300 rounded-xl p-3">
                    <Lock className="text-yellow-600 ml-2" size={20} aria-hidden="true" />
                    <input
                      type="password"
                      className="w-full bg-transparent text-gray-900 placeholder-gray-400 outline-none"
                      placeholder="أعد إدخال كلمة المرور"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className="w-5 h-5 text-yellow-600 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    required
                  />
                  <label htmlFor="terms" className="mr-2 text-sm text-gray-800">
                    أوافق على{' '}
                    <Link to="/terms-and-conditions" className="text-yellow-600 hover:underline">
                      الشروط والأحكام وسياسة الخصوصية
                    </Link>
                  </label>
                </div>
              </>
            )}

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-yellow-600 to-yellow-700 text-white py-4 rounded-xl font-bold hover:opacity-90 transition-opacity"
              disabled={isLoading}
            >
              {isLoading
                ? isLogin
                  ? 'جاري تسجيل الدخول...'
                  : 'جاري إنشاء الحساب...'
                : isLogin
                ? 'تسجيل الدخول'
                : 'إنشاء حساب'}
            </button>

            <p className="text-center text-gray-900/70">
              {isLogin ? 'ليس لديك حساب؟' : 'لديك حساب بالفعل؟'}{' '}
              <button
                type="button"
                onClick={() => setIsLogin(!isLogin)}
                className="text-yellow-600 hover:text-yellow-700 font-medium"
              >
                {isLogin ? 'سجل الآن' : 'سجل الدخول'}
              </button>
            </p>
          </form>
        </div>
      </main>
    </>
  );
};

export default Auth;
