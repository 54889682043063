// src/services/mutations/game/useReportQuestion.ts

import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../api';

interface ReportQuestionParams {
  questionId: number;
  reason: string;
}

const useReportQuestion = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ questionId, reason }: ReportQuestionParams) => {
      // Calls the backend endpoint: POST /api/questions/<questionId>/report/
      const { data } = await api.post(`/game/questions/${questionId}/report/`, {
        reason,
      });
      return data;
    },
    {
      onSuccess: () => {
        // Optionally invalidate or refetch queries if needed
        // e.g., queryClient.invalidateQueries(['resume-game']);
      },
    }
  );
};

export default useReportQuestion;
