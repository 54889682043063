import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // For SEO
import CategoriesSlider from '../components/Layout/CategoriesSlider';
import { Users, Sparkles, Trophy, Brain, Target } from 'lucide-react';
import logo from '../assets/logo/logo.png';

const steps = [
  {
    step: 1,
    title: 'تشكيل الفرق',
    desc: 'قم بتشكيل بين 2 إلى 4 فرق وسمِّ كل فريق بطريقة مبتكرة.',
  },
  {
    step: 2,
    title: 'اختيار الفئات',
    desc: 'إذا كان اللعب بفريقين، يختار كل فريق 3 فئات. أما إذا كان اللعب بثلاثة أو أربعة فرق، يختار كل فريق فئتين.',
  },
  {
    step: 3,
    title: 'اختيار الأسئلة',
    desc: 'اختر مستوى الصعوبة المناسب (300/500/700 نقطة) وابدأ التحدي بأسلوب سؤال وجواب مشوّق.',
  },
  {
    step: 4,
    title: 'الإجابة والتحدي',
    desc: 'لكل سؤال، دقيقة واحدة للفريق الذي يبدأ، ثم 30 ثانية للفرق الأخرى. في حال اللعب بثلاث فرق، يُمنح الفريق الأول دقيقة، والفرق الأخرى 15 ثانية لكل منها.',
  },
];

const GravityBall = ({ ball, onAnimationEnd }) => {
  return (
    <div
      className="absolute rounded-full"
      style={{
        left: ball.x,
        top: ball.y,
        width: ball.size,
        height: ball.size,
        background: 'radial-gradient(circle, #FCD34D, #F59E0B)',
        animation: `fall 1.5s ease-out ${ball.delay}s forwards`,
      }}
      onAnimationEnd={() => onAnimationEnd(ball.id)}
    />
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [balls, setBalls] = useState([]);

  const handleHeroClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const clickY = e.clientY - rect.top;

    // Generate a burst of 10 small balls with randomized offsets, sizes, and delays
    const newBalls = Array.from({ length: 10 }, (_, i) => ({
      id: Date.now() + i,
      x: clickX + (Math.random() * 40 - 20),
      y: clickY + (Math.random() * 40 - 20),
      delay: Math.random() * 0.5,
      size: Math.random() * 5 + 5, // Size between 5 and 10 pixels
    }));

    setBalls((prev) => [...prev, ...newBalls]);
  };

  const removeBall = (id) => {
    setBalls((prev) => prev.filter((ball) => ball.id !== id));
  };

  return (
    <div className="relative min-h-screen bg-white overflow-hidden">
      {/* SEO Helmet */}
      <Helmet>
        <html lang="ar" dir="rtl" />
        <title>Jawb - لعبة سؤال وجواب </title>
        <meta
          name="description"
          content="Jawb, لعبة سؤال وجواب تفاعلية وممتعة، تتيح لك اختبار معلوماتك والتنافس مع أصدقائك في تحديات . انضم الآن واستمتع بتجربة Jawbgame الفريدة."
        />
        <meta
          name="keywords"
          content="jawb, jawbgame, so2al jawab, question answer, لعبة سؤال وجواب, تحدي, معلومات, ترفيه, سؤال, جواب"
        />
        <link rel="canonical" href="https://www.jawb.com/" />
        <meta property="og:title" content="Jawb - لعبة سؤال وجواب | Jawbgame" />
        <meta
          property="og:description"
          content="Jawb, لعبة سؤال وجواب تفاعلية وممتعة، تتيح لك اختبار معلوماتك والتنافس مع أصدقائك في تحديات مثيرة. انضم الآن واستمتع بتجربة Jawbgame الفريدة."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://jawb.s3.eu-west-2.amazonaws.com/logo/logo.png" />
      </Helmet>

      {/* Animated Blob Background Elements with Yellow Accents */}
      <div className="absolute inset-0 z-0">
        <div className="absolute -top-40 -left-40 w-80 h-80 bg-yellow-300 rounded-full filter blur-3xl opacity-30 animate-blob"></div>
        <div className="absolute top-20 right-0 w-64 h-64 bg-yellow-400 rounded-full filter blur-3xl opacity-30 animate-delay-2000"></div>
        <div className="absolute bottom-10 left-10 w-96 h-96 bg-yellow-300 rounded-full filter blur-3xl opacity-30 animate-delay-4000"></div>
      </div>

      {/* Hero Section with Gravity Balls on Click */}
      <div
        className="relative z-10 flex flex-col items-center justify-center min-h-screen px-6 py-6"
        onClick={handleHeroClick}
      >
        <section className="text-center relative">
          {/* Render Gravity Balls */}
          {balls.map((ball) => (
            <GravityBall key={ball.id} ball={ball} onAnimationEnd={removeBall} />
          ))}
          {/* Logo */}
          <div className="relative inline-block">
            <img src={logo} alt="Jawb Logo" className="w-48 mx-auto" />
          </div>
          <p className="mt-6 mb-6 text-xl md:text-2xl text-gray-900 max-w-3xl mx-auto leading-relaxed">
             لعبة سؤال وجواب تفاعلية وممتعة. اختبر معلوماتك وتنافس مع أصدقائك في تجربة 
            الفريدة.
          </p>
          <button
            onClick={() => navigate('/combined-game-setup')}
            className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-8 py-3 rounded-full text-lg font-medium shadow-lg transform hover:scale-105 transition-transform duration-300"
          >
            ابدأ اللعب الآن
          </button>
        </section>
      </div>

      {/* Remaining sections below */}
      <div className="relative z-10 mt-0 px-6 py-12 space-y-16">
        {/* Categories Slider Section */}
        <CategoriesSlider />

        {/* Game Rules Section */}
        <section className="w-full max-w-6xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-8 text-yellow-500">
            قوانين اللعبة
          </h2>
          <div className="space-y-8">
            <div className="flex flex-col md:flex-row items-center gap-4">
              <Users className="w-10 h-10 text-yellow-600" />
              <p className="text-lg md:text-xl text-gray-900">
                يتنافس بين <span className="font-semibold">2 إلى 4 فرق</span>
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <Target className="w-10 h-10 text-yellow-600" />
              <p className="text-lg md:text-xl text-gray-900">
                اختيار الفئات: إذا كان اللعب بفريقين، يختار كل فريق 3 فئات؛ وإذا كان اللعب بثلاثة أو أربعة فرق، يختار كل فريق فئتين.
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <Brain className="w-10 h-10 text-yellow-600" />
              <p className="text-lg md:text-xl text-gray-900">
                أسئلة متدرجة الصعوبة: <span className="font-semibold">300، 500، 700 نقطة</span>
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <Sparkles className="w-10 h-10 text-yellow-600" />
              <p className="text-lg md:text-xl text-gray-900">
                لكل سؤال: الفريق المختار يبدأ بدقيقة للإجابة، ثم تُمنح الفرص للفرق المنافسة لمدة 30 ثانية لكل منها.
              </p>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-300"></div>
        </section>

        {/* How to Play Section with Timeline Roadmap */}
        <section className="w-full max-w-6xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-12 text-yellow-500">
            خطوات اللعب
          </h2>
          <div className="relative">
            <div className="absolute left-6 md:left-1/2 transform md:-translate-x-1/2 h-full border-l-2 border-gray-300"></div>
            <div className="space-y-12">
              {steps.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-col md:flex-row items-center ${
                    index % 2 === 0 ? 'md:justify-start' : 'md:justify-end'
                  } relative`}
                >
                  <div className="md:w-1/2 p-4 bg-white rounded-xl border border-gray-300 hover:shadow-xl transition-all duration-300">
                    <h3 className="text-2xl font-bold mb-2 text-yellow-500">{item.title}</h3>
                    <p className="text-lg text-gray-900">{item.desc}</p>
                  </div>
                  <div className="absolute left-6 md:left-1/2 transform md:-translate-x-1/2 bg-yellow-600 rounded-full border-4 border-yellow-500 w-8 h-8 flex items-center justify-center font-bold text-white">
                    {item.step}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-12 border-t border-gray-300"></div>
        </section>

        {/* Why Play With Us Section */}
        <section className="w-full max-w-6xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-8 text-yellow-500">
            لماذا تلعب معنا؟
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center space-y-4 p-6 bg-white rounded-2xl border border-gray-300 hover:shadow-2xl transition-all duration-300">
              <Trophy className="w-12 h-12 text-yellow-600" />
              <h3 className="text-2xl font-bold text-yellow-500">تحدي مستمر</h3>
              <p className="text-lg text-gray-900">
                اختبر معلوماتك في بيئة تنافسية تجمع بين المتعة والإثارة.
              </p>
            </div>
            <div className="flex flex-col items-center text-center space-y-4 p-6 bg-white rounded-2xl border border-gray-300 hover:shadow-2xl transition-all duration-300">
              <Sparkles className="w-12 h-12 text-yellow-600" />
              <h3 className="text-2xl font-bold text-yellow-500">تفاعل اجتماعي</h3>
              <p className="text-lg text-gray-900">
                العب مع أصدقائك واستمتعوا بتجربة تفاعلية فريدة.
              </p>
            </div>
            <div className="flex flex-col items-center text-center space-y-4 p-6 bg-white rounded-2xl border border-gray-300 hover:shadow-2xl transition-all duration-300">
              <Brain className="w-12 h-12 text-yellow-600" />
              <h3 className="text-2xl font-bold text-yellow-500">تعليم وترفيه</h3>
              <p className="text-lg text-gray-900">
                تعلم واختبر معلوماتك من خلال تحديات مبتكرة ومسلية.
              </p>
            </div>
          </div>
        </section>

        {/* Final Call-to-Action */}
        <section className="text-center">
          <button
            onClick={() => navigate('/combined-game-setup')}
            className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-8 py-3 rounded-full text-lg font-medium shadow-xl hover:scale-105 transition-transform duration-300"
          >
            ابدأ اللعب الآن
          </button>
        </section>
      </div>
    </div>
  );
};

export default Home;
