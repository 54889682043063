// src/services/queries/game.ts
import { useQuery } from '@tanstack/react-query';
import api from '../api';

export interface TeamResponse {
  id: number;
  name: string;
  score: number;
  call_friend: boolean;
  two_answers: boolean;
  replace_question: boolean;
  // ... any extra fields
}

export interface CategoryResponse {
  id: number;
  name: string;
  description: string;
  image: string | null;
}

export interface GameResponse {
  id: number;
  user: number;
  name: string;
  categories: CategoryResponse[];
  teams: TeamResponse[];
  status: string;
  current_turn: number;
  progress_data: Record<string, any>;
  created_at: string;
  updated_at: string;
  questions?: any[]; // You may optionally include questions if needed.
}

export interface QuestionResponse {
  id: number;
  category: number;
  question_text: string;
  answer: string;
  difficulty: 'easy' | 'medium' | 'hard';
  media_items: Array<{
    id: number;
    media: string;
    media_type: 'image' | 'video';
    position: 'before' | 'with' | 'after';
  }>;
}

export const fetchResumeGame = async (): Promise<GameResponse> => {
  const res = await api.get('/game/resume-game/');
  return res.data;
};

export const useResumeGameQuery = () => {
  return useQuery(['resume-game'], fetchResumeGame, {
    // Prevent refetching that would overwrite local state.
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const fetchQuestions = async (categoryId: number): Promise<QuestionResponse[]> => {
  const res = await api.get(`/questions/${categoryId}/list/`);
  return res.data;
};

export const useQuestionsQuery = (categoryId: number) => {
  return useQuery(['questions', categoryId], () => fetchQuestions(categoryId));
};
