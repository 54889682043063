import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/logo.png';
import { useCategories } from '../../services/queries/categoryQueries';

const Footer: React.FC = () => {
  const { data: categories, isLoading, isError } = useCategories();

  return (
    <footer className="bg-white border-t border-gray-300 py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Company Info */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <img src={logo} alt="Logo" className="w-20" />
            </div>
            <p className="text-gray-700 text-sm leading-relaxed">
              لعبة ثقافية تنافسية تختبر معلوماتك ومهاراتك بطريقة تفاعلية وممتعة.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-gray-900 font-bold mb-4">روابط سريعة</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/combined-game-setup" 
                  className="text-gray-700 hover:text-gray-900 transition-colors"
                >
                  ابدأ اللعب
                </Link>
              </li>
              <li>
                <Link 
                  to="/about" 
                  className="text-gray-700 hover:text-gray-900 transition-colors"
                >
                  عن اللعبة
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact" 
                  className="text-gray-700 hover:text-gray-900 transition-colors"
                >
                  تواصل معنا
                </Link>
              </li>
            </ul>
          </div>

          {/* Game Categories */}
          <div>
            <h3 className="text-gray-900 font-bold mb-4">فئات الأسئلة</h3>
            <div className="flex flex-wrap gap-2">
              {isLoading ? (
                <span>Loading...</span>
              ) : isError ? (
                <span>Error loading categories</span>
              ) : (
                categories?.map((category) => (
                  <span 
                    key={category.name}  // Using category.name as the unique key
                    className="bg-gray-100 text-gray-700 text-sm px-3 py-1 rounded-full"
                  >
                    {category.name}
                  </span>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-300 mt-12 pt-8 text-center">
          <p className="text-gray-500 text-sm">
            جميع الحقوق محفوظة © {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
