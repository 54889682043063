// src/components/questionscreen/QuestionOverlays.tsx
import React from 'react';

// 1) PhoneCallConfirm
export interface PhoneCallConfirmProps {
  displayedTeamName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const PhoneCallConfirm: React.FC<PhoneCallConfirmProps> = ({
  displayedTeamName,
  onConfirm,
  onCancel,
}) => (
  <div className="absolute inset-0 bg-black/60 flex items-center justify-center z-50 p-4">
    <div className="bg-yellow-100 p-4 rounded-md text-center">
      <h3 className="text-yellow-800 font-bold text-lg mb-3">
        هل تريد استخدام قدرة الاتصال لفريق {displayedTeamName}؟
      </h3>
      <div className="flex justify-center gap-4">
        <button onClick={onConfirm} className="bg-green-500 hover:bg-green-600 px-3 py-1 rounded text-white">
          تأكيد
        </button>
        <button onClick={onCancel} className="bg-red-500 hover:bg-red-600 px-3 py-1 rounded text-white">
          إلغاء
        </button>
      </div>
    </div>
  </div>
);

// 2) PhoneCallOverlay
export interface PhoneCallOverlayProps {
  timer: number;
  onEndCall: () => void;
}

export const PhoneCallOverlay: React.FC<PhoneCallOverlayProps> = ({ timer, onEndCall }) => (
  <div className="absolute inset-0 bg-black/80 flex flex-col items-center justify-center z-50 space-y-6">
    <p className="text-6xl text-white font-bold">{timer}</p>
    <p className="text-2xl text-white">الرجاء استخدام الهاتف خلال هذه المدة</p>
    <button
      onClick={onEndCall}
      className="bg-red-600 hover:bg-red-700 px-4 py-2 rounded text-white font-bold"
    >
      إنهاء المكالمة
    </button>
  </div>
);

// 3) ReplaceQuestionConfirm
export interface ReplaceQuestionConfirmProps {
  pickerTeamName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ReplaceQuestionConfirm: React.FC<ReplaceQuestionConfirmProps> = ({
  pickerTeamName,
  onConfirm,
  onCancel,
}) => (
  <div className="absolute inset-0 bg-black/60 flex items-center justify-center z-50 p-4">
    <div className="bg-yellow-100 p-4 rounded-md text-center">
      <h3 className="text-yellow-800 font-bold text-lg mb-3">
        {pickerTeamName}, هل أنت متأكد من استبدال السؤال؟
      </h3>
      <div className="flex justify-center gap-4">
        <button onClick={onConfirm} className="bg-green-500 hover:bg-green-600 px-3 py-1 rounded text-white">
          تأكيد
        </button>
        <button onClick={onCancel} className="bg-red-500 hover:bg-red-600 px-3 py-1 rounded text-white">
          إلغاء
        </button>
      </div>
    </div>
  </div>
);
