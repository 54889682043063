import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogOut, Menu, X } from 'lucide-react';
import { getAuthData, clearAuthData } from '../../services/mutations/auth/storage';
import logo from '../../assets/logo/logo.png';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGames, setUserGames] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const checkAuth = () => {
    const { access, first_name, userGames } = getAuthData();
    const loggedIn = access !== null;
    setIsLoggedIn(loggedIn);
    if (loggedIn) {
      setUserName(first_name || '');
      setUserGames(userGames ? parseInt(userGames, 10) : 0);
    }
  };

  useEffect(() => {
    checkAuth();
    window.addEventListener('storage', checkAuth);
    // Listen for our custom event to update the game count
    const handleGameCountUpdated = (e: Event) => {
      const customEvent = e as CustomEvent<number>;
      setUserGames(customEvent.detail);
    };
    window.addEventListener('gameCountUpdated', handleGameCountUpdated);
    return () => {
      window.removeEventListener('storage', checkAuth);
      window.removeEventListener('gameCountUpdated', handleGameCountUpdated);
    };
  }, []);

  const handleLogout = () => {
    clearAuthData();
    window.dispatchEvent(new Event('storage'));
    navigate('/', { replace: true });
    setIsLoggedIn(false);
    setUserName('');
    setUserGames(0);
  };

  return (
    <nav className="safe-area-padding bg-yellow-100 border-b border-yellow-300 shadow py-4">
      <div className="container mx-auto px-6 flex items-center justify-between">
        {/* Left: Logo */}
        <div className="flex items-center gap-3">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Logo" className="w-20" />
          </Link>
        </div>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="text-gray-800 focus:outline-none"
          >
            {mobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>

        {/* Center: Navigation Links (Desktop) */}
        <div className="hidden md:flex items-center gap-8">
          <Link 
            to="/combined-game-setup" 
            className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
          >
            ابدأ اللعب
          </Link>
          <Link 
            to="/" 
            className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
          >
            عن اللعبة
          </Link>
          <Link 
            to="/contact" 
            className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
          >
            تواصل معنا
          </Link>
        </div>

        {/* Right: User Actions */}
        <div className="flex items-center gap-4">
          {isLoggedIn ? (
            <>
              <div className="hidden md:flex flex-col items-end">
                <span className="text-sm font-semibold text-gray-800">
                  مرحباً، {userName}
                </span>
                <span className="text-xs text-gray-600">
                  عدد الألعاب: {userGames}
                </span>
              </div>
              <button
                onClick={() => navigate('/my-games')}
                className="bg-yellow-100 hover:bg-yellow-200 text-gray-800 px-5 py-2 rounded-lg transition-colors text-sm font-medium"
              >
                ألعابي
              </button>
              <button
                onClick={() => navigate('/buy-games')}
                className="bg-yellow-200 hover:bg-yellow-300 text-gray-800 px-5 py-2 rounded-lg transition-colors text-sm font-medium"
              >
                شراء ألعاب
              </button>
              <button
                onClick={handleLogout}
                className="flex items-center gap-1 text-red-500 hover:text-red-600 transition-colors text-sm font-medium"
              >
                <LogOut className="w-5 h-5" />
                تسجيل الخروج
              </button>
            </>
          ) : (
            <>
              <Link 
                to="/auth" 
                className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
              >
                تسجيل الدخول
              </Link>
              <Link 
                to="/combined-game-setup" 
                className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white hover:opacity-90 px-6 py-2 rounded-full transition-colors font-bold text-lg"
              >
                العب
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Mobile Menu (for small screens) */}
      {mobileMenuOpen && (
        <div className="md:hidden mt-4">
          <div className="flex flex-col gap-4 px-6">
            <Link 
              to="/combined-game-setup" 
              onClick={() => setMobileMenuOpen(false)}
              className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
            >
              ابدأ اللعب
            </Link>
            <Link 
              to="/" 
              onClick={() => setMobileMenuOpen(false)}
              className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
            >
              عن اللعبة
            </Link>
            <Link 
              to="/contact" 
              onClick={() => setMobileMenuOpen(false)}
              className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
            >
              تواصل معنا
            </Link>
            {isLoggedIn ? (
              <>
                <div className="flex flex-col items-start">
                  <span className="text-sm font-semibold text-gray-800">
                    مرحباً، {userName}
                  </span>
                  <span className="text-xs text-gray-600">
                    عدد الألعاب: {userGames}
                  </span>
                </div>
                <button
                  onClick={() => { setMobileMenuOpen(false); navigate('/my-games'); }}
                  className="bg-yellow-100 hover:bg-yellow-200 text-gray-800 px-5 py-2 rounded-lg transition-colors text-sm font-medium"
                >
                  ألعابي
                </button>
                <button
                  onClick={() => { setMobileMenuOpen(false); navigate('/buy-games'); }}
                  className="bg-yellow-200 hover:bg-yellow-300 text-gray-800 px-5 py-2 rounded-lg transition-colors text-sm font-medium"
                >
                  شراء ألعاب
                </button>
                <button
                  onClick={() => { setMobileMenuOpen(false); handleLogout(); }}
                  className="flex items-center gap-1 text-red-500 hover:text-red-600 transition-colors text-sm font-medium"
                >
                  <LogOut className="w-5 h-5" />
                  تسجيل الخروج
                </button>
              </>
            ) : (
              <>
                <Link 
                  to="/auth" 
                  onClick={() => setMobileMenuOpen(false)}
                  className="text-gray-800 hover:text-gray-900 transition-colors text-lg font-medium"
                >
                  تسجيل الدخول
                </Link>
                <Link 
                  to="/combined-game-setup" 
                  onClick={() => setMobileMenuOpen(false)}
                  className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white hover:opacity-90 px-6 py-2 rounded-full transition-colors font-bold text-lg"
                >
                  العب
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
