import React from 'react';

interface LeaveGameModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const LeaveGameModal: React.FC<LeaveGameModalProps> = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black/75 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-6 max-w-md w-full shadow-2xl border border-yellow-300">
        <h2 className="text-2xl font-bold mb-4 text-center text-yellow-800">
          هل أنت متأكد أنك تريد مغادرة اللعبة؟
        </h2>
        <div className="flex justify-around">
          <button
            onClick={onConfirm}
            className="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-full text-white font-bold"
          >
            نعم
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-full text-yellow-800 font-bold"
          >
            لا
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveGameModal;
