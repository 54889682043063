import React from 'react';
import { X } from 'lucide-react';

interface RulesModalProps {
  onClose: () => void;
}

const RulesModal: React.FC<RulesModalProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-2xl p-6 w-full max-w-2xl md:max-w-4xl relative shadow-2xl border border-yellow-300">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-yellow-700">قواعد اللعبة</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-yellow-100 rounded-full transition-colors"
          >
            <X className="w-6 h-6 text-yellow-700" />
          </button>
        </div>

        {/* Main Content: Rules */}
        <div className="space-y-4 text-gray-700">
          <p>
            <span className="font-bold text-yellow-700">1. يتنافس بين 2 إلى 4 فرق:</span> يمكن أن تتراوح عدد الفرق المشاركة في اللعبة من فريقين إلى أربعة فرق.
          </p>
          <p>
            <span className="font-bold text-yellow-700">2. اختيار الفئات:</span> إذا كان اللعب بفريقين، يختار كل فريق 3 فئات؛ وإذا كان اللعب بثلاثة أو أربعة فرق، يختار كل فريق فئتين.
          </p>
          <p>
            <span className="font-bold text-yellow-700">3. أسئلة متدرجة الصعوبة:</span> الأسئلة تأتي بثلاثة مستويات من الصعوبة: 300، 500، و700 نقطة.
          </p>
          <p>
            <span className="font-bold text-yellow-700">4. آلية الإجابة:</span>
            <ul className="list-disc list-inside pl-4 mt-2">
              <li>الفريق المختار يبدأ بدقيقة للإجابة.</li>
              <li>تُمنح الفرص للفرق المنافسة لمدة 30 ثانية لكل منها.</li>
              <li>في حال اللعب بثلاث فرق، يُمنح الفريق الأول دقيقة تامة، والفرص للفرق الأخرى تكون 15 ثانية لكل منها.</li>
            </ul>
          </p>
          <p>
            <span className="font-bold text-yellow-700">5. القدرات الخاصة:</span>
            <ul className="list-disc list-inside pl-4 mt-2">
              <li>
                <span className="font-bold text-yellow-700">الاتصال:</span> يمكن استخدام القدرة مرة واحدة لكل لعبة، ومدة المكالمة يجب ألا تزيد عن 40 ثانية.
              </li>
              <li>
                <span className="font-bold text-yellow-700">استبدال السؤال:</span> تتيح هذه القدرة للفريق الحصول على سؤال بديل.
              </li>
              <li>
                <span className="font-bold text-yellow-700">جوابين:</span> تتيح هذه القدرة للفريق تقديم جوابين للسؤال.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RulesModal;
